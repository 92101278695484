.modalNote {
  display: flex;
  padding: 10px 7px 10px 15px;
}

.disable {
  color: rgba(255, 255, 255, 0.4);
}

.setting {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  font-size: 22px;
  font-weight: 500;
  position: relative;

  max-height: 500px;
}

.setting--expand {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  font-size: 22px;
  font-weight: 500;
  position: relative;

  width: 100%;
  height: 100%;
}

@media screen and (min-width: 800px) {
  .setting--expand {
    width: 800px;
    height: 100%;
  }
}

.setting-state {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  height: max-content;
  padding: 10px 2px 0px 10px;
}

.setting-state--expand {
  padding: 20px 4px 10px 4px;
}

.setting-state-checkbox {
  width: 18px;
  height: 18px;
  margin: 10px 6px 0px 6px;
  border-radius: 4.5px;
  transition: background-color 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;
  flex-shrink: 0;
}

.setting-state-checkbox--expand {
  margin-top: 10px;
}

.checkboxCompleted {
  background-image: url(../../assets/Common/timeblock-check-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.setting-state-checkbox:not(.disabled):hover {
  background-image: url(../../assets/Common/timeblock-check-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.setting-state-tool {
  width: 30px;
  aspect-ratio: 1 / 1;
  border-radius: 6px;
  background: url(../../assets/TaskDetail/integration-gmail-icon.svg);
  background-size: inherit;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  box-sizing: border-box;
}

.setting-state-tool:hover {
  border: 1px solid #545959;
}

.setting-title {
  position: relative;
  display: flex;
  width: 100%;
  height: max-content;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.setting-title--expand {
  width: 100%;
  display: flex;
  align-items: center;
  align-self: stretch;
  flex: 1 0 0;
}

.setting-title-clear {
  width: 16px;
  height: 16px;
  top: 13px;
  right: 10px;
  position: absolute;
  background: url(../../assets/TaskDetail/title-close.svg);
  background-size: inherit;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

@keyframes inputActive {
  from {
    background: rgba(26, 28, 28, 0);
  }

  to {
    background: rgba(26, 28, 28, 1);
  }
}

.setting-property {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  font-size: 14px;
  font-weight: 400;
  /* flex-grow: 1; */
  padding: 10px 2px 10px 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-sizing: border-box;
  gap: 10px;
}

.setting-property--expand {
  padding: 20px 10px;
  gap: 12px;
}

/* .setting-property::-webkit-scrollbar {
  display: none;
} */

.setting-property-btn > span {
  pointer-events: none;
}

/* .setting-property-mini{
    height:364px;
} */

.setting-property-btn {
  display: flex;
  /* gap:10px; */
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 36px;
  line-height: 36px;
  position: relative;
  gap: 10px;
}

.setting-property-icon {
  color: white;
}

.label-on {
  color: white;
}

.setting-property-resource {
  gap: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
}

.setting-property-modal {
  position: absolute;
  display: flex;
  top: 36px;
  width: 100%;
  left: 0px;
  padding: 10px 0px;
  max-height: 280px;
  background: rgba(54, 58, 58, 1);
  border: 1px solid rgba(84, 89, 89, 1);
  border-radius: 0px 0px 6px 6px;
  z-index: 1000;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  animation: modalFadeIn 250ms ㄹcubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.resource-item {
  width: 88px;
  height: 88px;
  border-radius: 10px;
  border: 1px solid rgba(84, 89, 89, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  position: relative;
}

.resource-item-delete {
  position: absolute;
  top: -7px;
  right: -7px;
  cursor: pointer;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 17px;
  background: #383a3a;
  border: 1px solid #abadad;
  border-radius: 100%;
  font-size: 8px;
  font-weight: 700;
  cursor: pointer;
  opacity: 0;
  transition: opacity 280ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.resource-item:hover .resource-item-delete {
  opacity: 1;
}

.resource-item:has(.resource-item-favicon) {
  background: rgba(54, 58, 58, 1);
}

.resource-item:has(.resource-item-text):hover,
.resource-item:has(.resource-item-text):hover .resource-item-text {
  color: white;
  border-color: white;
}

.resource-item-favicon {
  width: 30px;
  height: 30px;
  border: none;
  line-height: 22px;
  background-size: contain;
}

.resource-item span:nth-child(1) {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  text-align: center;
  line-height: 22px;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.resource-item-text {
  font-weight: 400;
  font-size: 12px;
  height: 17px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.4);
}

.setting > hr {
  height: 1px;
  background: rgba(84, 89, 89, 1);
  border: none;
  margin: 0px 0px;
  margin-bottom: 18px;
}

.date-toggle {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 9px;
}

.date-part {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.date-part-title {
  color: white;
  font-size: 12px;
  font-weight: 400;
}

.date-switch {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 8px;
}

.date-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.date-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.date-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: -4px;
  bottom: -4px;
  background-color: white;
  transition: 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.date-input:checked + .date-slider {
  background: rgba(105, 228, 255, 0.2);
}

.date-input:focus + .date-slider {
  box-shadow: 0 0 1px rgba(105, 228, 255, 0.2);
}

.date-input:checked + .date-slider:before {
  transform: translateX(16px);
  background: rgba(105, 228, 255, 1);
}

.date-slider {
  border-radius: 34px;
}

.date-slider:before {
  border-radius: 50%;
}

.date-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.date-dropdown-date {
  flex: 1 1;
  height: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.dropdown-date-title {
  text-align: center;
  border: 1px solid rgba(84, 89, 89, 1);
  border-radius: 6px;
  width: 100%;
  height: 100%;
  color: white;
  line-height: 30px;
}

.datepicker-wrap {
  position: absolute;
  top: 0px;
  z-index: 99999;
  animation: dropFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

.datepickerTimeWrap {
  position: absolute;
  top: 0px;
  z-index: 99999;
  animation: dropFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes dropFadeIn {
  from {
    opacity: 0;
    transform: translateY(-14px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.date-dropdown-time {
  width: 120px;
  height: 30px;
  border: 1px solid rgba(84, 89, 89, 1);
  color: white;
  border-radius: 6px;
  text-align: center;
  line-height: 30px;
  position: relative;
}

.googleMeetIcon {
  width: 60px;
  height: 40px;
  background-image: url(../../assets/TaskDetail/google-meet-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

.googleMeetIconDisabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.googleMeetIconHover {
  width: 60px;
  height: 40px;
  opacity: 0.7;
  background: #000;
  border-radius: 6px;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid #545959;
  cursor: pointer;
}

.googleMeetGoingIcon {
  margin-left: auto;
  display: flex;
  height: 24px;
  padding: 0px 6px;
  justify-content: flex-end;
  align-items: center;
  gap: 3px;
  color: #abadad;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  border-radius: 6px;
  border: 1px solid #545959;
  box-sizing: border-box;
  cursor: pointer;
}

.setting-save {
  height: 40px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 40px;
  background: rgba(84, 89, 89, 1);
  border-radius: 6px;
  background: var(--hover-big, rgba(255, 255, 255, 0.04));
  pointer-events: none;
  color: var(--badge-disabled, #484d4d);
  width: 300px;
}

.setting-save-mini {
  margin-top: 18px;
  margin-bottom: 20px;
}

.active {
  pointer-events: all;
  color: white;
  border-radius: 6px;
  background: var(--line-btn, #545959);
  transition: all 0.4s;
  cursor: pointer;
}

.active:hover {
  border-radius: 6px;
  background: var(--btn-line-hover, #696d6d);
  transition: background-color 0.2s;
}

.select_tab {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-top: 1px solid var(--w-16, rgba(255, 255, 255, 0.16));
  border-bottom: 1px solid var(--w-16, rgba(255, 255, 255, 0.16));
}

.select_tab--btn {
  display: flex;
  height: 30px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--w-16, rgba(255, 255, 255, 0.16));
}

.select_tab--btn > span {
  color: var(--whtie, #fff);
  text-align: center;

  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
