.expandHoverd:hover {
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.04);
  /* border: 1px solid rgba(255, 255, 255, 0.04); */
}

.expandSelected {
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: rgba(255, 255, 255, 0.04);
}

.isDragging {
  opacity: 0;
}

.integration {
  min-width: 14px;
  min-height: 14px;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: border-box;
}

.integration-gmail {
  background-image: url(../../../../assets/Main/gmail-icon.svg);
}

.integration-jira {
  background-image: url(../../../../assets/Main/jira-icon.svg);
}

.integration-slack {
  background-image: url(../../../../assets/Main/slack-icon.svg);
}

.dragOver {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 6px;
}
