.wrap {
  position: fixed;
  display: flex;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  z-index: 111111;
}

.main {
  width: 340px;
  height: 220px;
  background: rgba(54, 58, 58, 1);
  border: 1px solid rgba(84, 89, 89, 1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  animation: fadeIn 250ms cubic-bezier(0, 1, 0, 1) forwards;
}

@keyframes fadeIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.header {
  width: calc(100% - 26px);
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.header-title {
  display: flex;
  height: 22px;
  gap: 13px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.header-title:after {
  content: "";
  position: absolute;
  width: 26px;
  height: 2px;
  bottom: 0px;
  left: 0px;
  background: white;
  transition: all 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.header-title-file:after {
  width: 23px;
  left: 38px;
}

.header-title span {
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.header-off {
  opacity: 0.4;
}

.header-close {
  cursor: pointer;
}

.body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.body-input-url,
.body-input-name {
  background: rgba(26, 28, 28, 1);
  width: 300px;
  height: 36px;
  border: none;
  caret-color: rgba(107 220 255);
  text-overflow: ellipsis;
  border-radius: 6px;
  color: white;
  padding: 0px 10px;
  font-size: 14px;
  font-weight: 400;
  text-overflow: ellipsis;
  animation: inputBtnFadeIn 200ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  outline: none;
}

@keyframes inputBtnFadeIn {
  from {
    opacity: 0;
    transform: translateX(-40px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

/* .body-input::placeholder { color: rgba(171, 173, 173, .4); } */
.body-input:focus {
  outline: none;
  /* animation:inputActive 100ms cubic-bezier(0, 0, 1, 1) forwards; */
  color: white;
}

.body-input-url::placeholder,
.body-input-name::placeholder {
  color: #7c7d7d;
}

.body-fileSelect-btn {
  width: 260px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  cursor: pointer;
  animation: fileSelectBtnFadeIn 200ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes fileSelectBtnFadeIn {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.body-file-btn {
  width: 300px;
  height: 36px;
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.2);
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.body-file-icon {
  margin: 0px 10px;
  width: 16px;
  height: 16px;
  background: white;
}

.body-file-title {
  flex: 1;
  font-size: 14px;
  font-weight: 400;
}

.body-file-cancel {
  width: 18px;
  height: 18px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 100%;
  margin: 0px 10px;
  text-align: center;
  font-weight: 600;
  font-size: 9px;
  color: white;
  line-height: 18px;
}

.footer {
  width: 300px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(84, 89, 89, 1);
  border-radius: 6px;
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: white;
  line-height: 40px;
  cursor: pointer;
}
