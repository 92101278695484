.tooltip {
  position: relative;
  display: inline-block;
  border: none;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 74px;
  background-color: white;
  color: #1a1c1c;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -37px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.11px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
