.calendar-custom {
  flex-grow: 1;
  height: 100dvh;
  background: #1a1c1c;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overlayView {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0);
}
