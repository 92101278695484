.setting-property-resource {
  width: 100%;
  height: max-content;
  min-height: 30px;

  /* gap: 6px; */
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;

  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.property--links__add {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  gap: 8px;
  flex: 1 0 0;

  color: var(--placeholder, #7c7d7d);
  align-items: center;
}

.resource-item {
  position: relative;
  display: flex;

  width: max-content;
  max-width: 300px;
  height: 30px;

  padding: 0px 6px;
  gap: 6px;

  justify-content: center;
  align-items: center;
  justify-items: center;
  cursor: pointer;

  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--hover-big, rgba(255, 255, 255, 0.04));
  overflow: visible;
}

.resource-item:hover {
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.resource-item:hover .resource-item-more {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  opacity: 1;
  border-radius: 5px;
  background: var(--dropdown_dark, rgba(36, 38, 38, 0.8));
  backdrop-filter: blur(2px);
}

.resource-item-favicon {
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  border: none;
  line-height: 30px;
  background-size: contain;
}

.resource_name {
  width: max-content;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;

  overflow: hidden;
  color: var(--g100, #d9d9d9);
  text-overflow: ellipsis;

  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.resource-item-more {
  position: absolute;
  right: 2px;

  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 5px;
  background: var(--dropdown_dark, rgba(36, 38, 38, 0.8));
  backdrop-filter: blur(2px);
  opacity: 0;
}

.resource-item-more__icon {
  fill: #abadad;
}

.resource-item-more:hover .resource-item-more__icon {
  fill: white;
}

.property__empty_title {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  height: 30px;
  padding: 0px 4px;
  align-items: center;
  border-radius: 5px;
  flex: 1 0 0;
  color: var(--placeholder, #7c7d7d);
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.property__empty_title:hover {
  border-radius: 5px;
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.property__empty_title > span {
  line-height: 22px;
}

/* Modal */
.modal {
  position: absolute;
  top: 29px;
  right: 0;
  display: flex;
  width: 100px;
  height: fit-content;
  background: #363a3a;
  border: 1px solid #545959;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  z-index: 9999;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.modal > * {
  height: 30px;
  width: 100%;
  animation: fadeIn 350ms cubic-bezier(0, 0.6, 0, 1) forwards;
  font-family: "Pretendard Variable";
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  box-sizing: border-box;
  padding: 0px 10px;
}

.modal > *:hover {
  background: rgba(255, 255, 255, 0.08);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
