.setting-property {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  font-size: 14px;
  font-weight: 400;
  /* flex-grow: 1; */
}

.setting-property__btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  line-height: 36px;
  /* gap: 6px; */
}

.setting-property__guests-contents {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
}

.guests-info__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 30px;
  padding: 0px 4px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}

.guests-info__count {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;

  color: var(--whtie, #fff);
  text-overflow: ellipsis;

  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.guests-info__add-btn {
  cursor: pointer;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.guests-info__add-btn:hover {
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.property__empty_title {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  height: 30px;
  padding: 0px 4px;
  align-items: center;
  border-radius: 5px;

  color: var(--placeholder, #7c7d7d);
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  position: relative;
}

.property__empty_title:hover {
  border-radius: 5px;
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.property__empty_title > span {
  line-height: 22px;
}

.selected-guest-wrap {
  margin: 6px 0;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.selected-guest-wrap--expand {
  margin: 6px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

@keyframes hoverFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.input_box {
  display: flex;
  position: relative;
}

.guests-input {
  display: flex;
  min-height: 30px;
  background: none;
  border: none;
  caret-color: rgba(107 220 255);
  border-radius: 5px;
  color: white;
  padding: 0px 4px;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  text-overflow: ellipsis;

  height: 30px;
  position: relative;
}

.guests-input:hover {
  background: rgba(255, 255, 255, 0.04);
}
.guests-input:focus {
  outline: none;
  color: white;
  background: rgba(25, 27, 27, 1);
}

.guests-input:focus::placeholder {
  color: var(--placeholder, #7c7d7d);
}

.validationError .guests-input {
  color: #ff6464;
}

.validationErrorIcon {
  justify-self: center;
  align-self: center;
  position: absolute;
  right: 6px;
  width: 16px;
  height: 16px;
  background: url(../../../assets/TaskDetail/validation-error.svg);
}

.guests-modal {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: transparent;
  top: 37px;
  left: 0px;
  padding: 4px 0px 4px 8px;
  /* width: 310px; */
  max-height: 275px;
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--task-task_dropdown, #2f3131);
  box-sizing: border-box;
  z-index: 1000;
  animation: modalFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.showScrollbar.guests-modal {
  padding: 4px 0px 4px 8px;
}

.guests-modal::-webkit-scrollbar {
  width: 8px;
}

.showScrollbar.guests-modal::-webkit-scrollbar {
  width: 8px;
  display: block;
}

.guests-modal::-webkit-scrollbar-thumb {
  background: transparent;
  width: 2px;
  height: 100px;
  background-clip: padding-box;
  border: 3px solid transparent;
  transition: background 0.5s ease;
  opacity: 0;
}

.showScrollbar.guests-modal::-webkit-scrollbar-thumb {
  background: var(--w-16, rgba(255, 255, 255, 0.16));
  width: 2px;
  height: 100px;
  background-clip: padding-box;
  border: 3px solid transparent;
  opacity: 1;
}

.guests-modal::-webkit-scrollbar-thumb:hover {
  background: rgba(84, 89, 89, 1);
  border-radius: 99px;
  background-clip: padding-box;
  border: 2px solid transparent;
  width: 4px;
  height: 100px;
}

.guests-modal::-webkit-scrollbar-track {
  background: transparent;
}

/* 
.guests-modal::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0);
  border-radius: 10px;
  border: 5px solid rgba(54, 58, 58, 1);
}

.guests-modal:hover::-webkit-scrollbar-thumb {
  background: rgba(97, 97, 97, 1);
}

.guests-modal::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 8px;
}

.guests-modal::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
} */

/* SelectedGuestRow */

.selected-guest-row {
  box-sizing: border-box;
  display: flex;
  width: max-content;
  height: 30px;
  max-width: 300px;
  padding: 0px 8px;
  align-items: center;
  gap: 6px;

  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--hover-big, rgba(255, 255, 255, 0.04));
  position: relative;
}

.selected-guest-row:hover {
  background: rgba(54, 58, 58, 1);
  cursor: default;
}

.selectedGuestRowIconWrapper {
  position: relative;
}

.selected-guest-row-icon {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.acceptedIcon {
  position: absolute;
  top: 4px;
  left: 8px;
  width: 12px;
  height: 12px;
  background: url(../../../assets/TaskDetail/accepted-icon.svg);
}

.declinedIcon {
  position: absolute;
  top: 4px;
  left: 8px;
  width: 12px;
  height: 12px;
  background: url(../../../assets/TaskDetail/declined-icon.svg);
}

.tentativeIcon {
  position: absolute;
  top: 4px;
  left: 8px;
  width: 12px;
  height: 12px;
  background: url(../../../assets/TaskDetail/tentative-icon.svg);
}

.selected-guest-row-name {
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: block;
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected-guest-row-email {
  color: white;
  font-weight: 400;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 24px;
  text-align: center;
  margin-right: 4px;
}

.organizerBadge {
  display: flex;
  height: 20px;
  padding: 0px 6px;
  align-items: center;
  border-radius: 99px;
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));

  justify-content: center;
  font-size: 11px;
  color: rgba(124, 125, 125, 1);
  flex-shrink: 0;
  color: var(--g100, #d9d9d9);
}

.selectedGuestRowDelete {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  transition: all 200ms cubic-bezier(0, 1, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;

  position: absolute;
  right: 3px;
}

.selectedGuestRowDelete:hover {
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--dropdown_dark, rgba(36, 38, 38, 0.8));
  backdrop-filter: blur(2px);
  transition: all 200ms cubic-bezier(0, 1, 0, 1);
}

.selectedGuestRowDelete:hover .selectedGuestRowDeleteIcon {
  fill: #fff;
}

.selectedGuestRowDeleteIcon {
  fill: none;
}

.selected-guest-row:hover .selectedGuestRowDelete {
  border-radius: 5px;
  background: var(--dropdown_dark, rgba(36, 38, 38, 0.8));
  backdrop-filter: blur(2px);
}
.selected-guest-row:hover .selectedGuestRowDeleteIcon {
  fill: rgba(255, 255, 255, 0.4);
}

.selectedGuestRowDelete:hover .selectedGuestRowDeleteIcon {
  transition: all 200ms cubic-bezier(0, 1, 0, 1);
  fill: #fff;
}

.selected-guest-row-delete {
  opacity: 0;
  visibility: hidden;
}

.selected-guest-row:hover .selected-guest-row-delete {
  opacity: 1;
  visibility: visible;
}

.selected-guest-row-delete:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

/* GuestRow */

.guest-row {
  width: 100%;
  height: 36px;
  /* position: relative; */
  -webkit-user-drag: none;
  user-select: none;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.guest-row:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.guest_row_active {
  background-color: rgba(255, 255, 255, 0.08);
}

.guest-row input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.guest-row__option {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 36px;
  padding: 0px 6px;
  gap: 4px;
  align-self: stretch;
}

.guest-row__icon-wrapper {
  position: relative;
}

.guest-row__icon {
  width: 16px;
  height: 16px;
  line-height: 16px;
  box-sizing: border-box;
  border-radius: 16px;
  margin: 0px 2px;
  flex-shrink: 0;
}

.guest-row__icon-checkmark {
  margin: 0px 2px;
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  background-image: url("../../../assets/Main/check-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  fill: #fff;
}

.guest-row__name,
.guest-row__email {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;

  overflow: hidden;
  font-family: "Pretendard Variable";
  font-weight: 400;
}

.guest-row__name {
  font-size: 14px;
  font-style: normal;
  line-height: 150%; /* 21px */
  color: white;
}

.guest-row__name--existing {
  color: var(--placeholder, #7c7d7d) !important;
}

.guest-row__email {
  color: var(--placeholder, #7c7d7d);
  font-size: 12px;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.12px;
}
