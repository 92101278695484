.wrap > * {
  user-select: none;
}

.wrap label,
.repeat-unit,
.repeat-unit-drop > span,
.repeat-month,
.repeat-month-drop > span,
.finished-on-date {
  cursor: pointer;
}

.wrap {
  position: fixed;
  display: flex;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  z-index: 1001;
}

.main {
  padding: 0px 20px;
  background: rgba(54, 58, 58, 1);
  border: 1px solid rgba(84, 89, 89, 1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  animation: fadeIn 250ms cubic-bezier(0, 1, 0, 1) forwards;
  z-index: 1002;
}

@keyframes fadeIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.header {
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.header-close {
  cursor: pointer;
  background-image: url(../../../assets/TaskDetail/close.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: inherit;
}

.body {
  width: 260px;
  /* height:36px; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: fit-content;
}

.body-part {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 260px;
  height: fit-content;
}

.body-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  color: white;
}

.body-row {
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.repeat-text {
  color: white;
  font-size: 14px;
  font-weight: 400;
}

.repeat-number::-webkit-input-placeholder,
.finished-after-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.wrap > input[name="repeat"] + .radio-control + .radio-label[for="on"] + .finished-on-date,
.wrap > input[name="repeat"] + .radio-control + .radio-label[for="after"] + .finished-after-input {
  color: rgba(255, 255, 255, 0.4);
}

.warp > input[name="repeat"]:checked + .radio-control + .radio-label[for="on"] + .finished-on-date,
.warp
  > input[name="repeat"]:checked
  + .radio-control
  + .radio-label[for="after"]
  + .finished-after-input {
  color: white;
}

.repeat-number,
.repeat-unit,
.finished-on-date,
.finished-after-input {
  padding: 0 0;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid rgba(84, 89, 89, 1);
  border-radius: 6px;
  color: white;
  text-align: center;
}

.repeat-number,
.finished-after-input {
  width: 70px;
}

.repeat-unit,
.finished-on-date {
  width: 120px;
}

.repeat-unit {
  position: relative;
}

.repeat-unit-drop {
  border-radius: 6px;
  border: 1px solid rgba(84, 89, 89, 1);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 34px;
  background: rgba(54, 58, 58, 1);
  padding: 10px;
  z-index: 100;
  animation: dropFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes dropFadeIn {
  from {
    opacity: 0;
    transform: translateY(-14px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.repeat-unit-drop > span {
  width: 100px;
  height: 25px;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: white;
  border-radius: 6px;
}

.repeat-unit-drop > span:hover {
  background-color: rgba(38, 41, 41, 1);
}

.row-repeat-day {
  margin: 10px 0px;
}

.repeat-day {
  width: 33px;
  height: 33px;
  border-radius: 33px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  line-height: 33px;
  color: white;
  cursor: pointer;
  background: rgba(105, 228, 255, 0);
  user-select: none;
  transition: all 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.repeat-day-on {
  color: rgba(54, 58, 58, 1);
  background: rgba(105, 228, 255, 1);
}

.repeat-month {
  width: 100%;
  height: 30px;
  border-radius: 6px;
  color: white;
  text-align: center;
  background: rgba(54, 58, 58, 1);
  border: 1px solid rgba(84, 89, 89, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  position: relative;
}

.repeat-month-drop {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-self: center;
  background: rgba(54, 58, 58, 1);
  border: 1px solid rgba(84, 89, 89, 1);
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  top: 34px;
  z-index: 100;
  text-align: left;
  animation: dropFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

.repeat-month-drop > span {
  width: 230px;
  /* height:25px; */
  padding: 0px 10px;
  border-radius: 6px;
}

.repeat-month-drop > span:hover {
  background-color: rgba(38, 41, 41, 1);
}

/* .repeat-day:after{
    width:33px;
    height:33px;
    border-radius: 33px;
    background-color:rgba(105, 228, 255, 1); 
    content: "";
    display: block;
    position: absolute;
} */

.body-input {
  background: rgba(38, 41, 41, 1);
  width: calc(264px - 16px);
  height: 100%;
  border: none;
  caret-color: rgba(107 220 255);
  text-overflow: ellipsis;
  border-radius: 6px;
  color: white;
  padding: 0px 8px;
  text-overflow: ellipsis;
}

/* .body-input::placeholder { color: rgba(171, 173, 173, .4); } */
.body-input:focus {
  outline: none;
  /* animation:inputActive 100ms cubic-bezier(0, 0, 1, 1) forwards; */
  color: white;
}

.finished-radio {
  font-size: 14px;
  color: white;
}

.radio-input {
  opacity: 0;
  position: absolute;
}

.radio-control {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 7px;
  vertical-align: middle;
  background-color: inherit;
  color: rgba(105, 228, 255, 1);
  border: 2px solid rgba(171, 173, 173, 1);
  border-radius: 24px;
}

.radio-input:checked + .radio-control:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background-color: rgba(105, 228, 255, 1);
  border-radius: 12px;
  animation: radioFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes radioFadeIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.radio-input:checked + .radio-control {
  border-color: rgba(105, 228, 255, 1);
}

.radio-control {
  transform: scale(0.75);
}

.radio-label {
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.finished-after {
  display: flex;
  gap: 12px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
}

.finished-after-text {
  font-size: 14px;
  color: white;
}

.datepicker-wrap {
  animation: dropFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

.footer {
  width: 260px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(84, 89, 89, 1);
  border-radius: 6px;
  margin-bottom: 25px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: white;
  line-height: 40px;
  cursor: pointer;
}

.footer-disabled {
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  color: var(--w-24, rgba(255, 255, 255, 0.24));
}
