/* 공통 스타일: moba와 moba-mini 모두에 적용 */
.bn-container * {
  font-size: 14px;
}

.bn-container[data-theming="moba"][data-color-scheme],
.bn-container[data-theming="moba-mini"][data-color-scheme] {
  --bn-font-family: "Pretendard Variable", "SF Pro Display", -apple-system, BlinkMacSystemFont,
    "Open Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --bn-colors-editor-background: #242626;
  --bn-colors-editor-text: rgba(255, 255, 255, 1);
  --bn-colors-side-menu: #7c7d7d;
  --bn-colors-menu-background: rgba(47, 49, 49, 1);
  --bn-colors-selected-background: rgba(255, 255, 255, 0.16) !important;
  --bn-colors-selected-text: rgba(217, 217, 217, 1) !important;
  --bn-colors-hovered-background: rgba(255, 255, 255, 0.08);
  --bn-colors-hovered-text: rgba(217, 217, 217, 1);
  --bn-colors-disabled-background: rgba(0, 0, 0, 0.1);
  --bn-colors-disabled-text: rgba(217, 217, 217, 1);
  --bn-colors-tooltip-background: rgba(72, 77, 77, 1);
  --bn-colors-shadow: none;
  --bn-colors-border: rgba(255, 255, 255, 0.08);
  --mantine-scale: 0.6;
  --mantine-font-size-xs: 12px;
}

/* 공통 스타일 */
.bn-container[data-theming="moba"],
.bn-container[data-theming="moba-mini"] {
  width: 100%;
}

.bn-container[data-theming="moba"] .bn-editor,
.bn-container[data-theming="moba-mini"] .bn-editor {
  padding-inline: 0;
}

.bn-container[data-theming="moba"] .bn-inline-content a,
.bn-container[data-theming="moba-mini"] .bn-inline-content a {
  color: #69e4ff;
}

.bn-container[data-theming="moba"] .bn-toolbar {
  height: 32px;
  gap: 4px;
}

.bn-container[data-theming="moba-mini"] .bn-toolbar {
  visibility: hidden;
}

.bn-container[data-theming="moba"] .bn-toolbar button,
.bn-container[data-theming="moba-mini"] .bn-toolbar button {
  height: 24px;
}

.bn-container[data-theming="moba"] .bn-toolbar .mantine-Menu-item,
.bn-container[data-theming="moba-mini"] .bn-toolbar .mantine-Menu-item {
  padding: 4px;
  margin-top: 4px;
}

.bn-container[data-theming="moba"] .bn-toolbar .mantine-Menu-item:first-of-type,
.bn-container[data-theming="moba-mini"] .bn-toolbar .mantine-Menu-item:first-of-type {
  margin-top: 0;
}

.bn-container[data-theming="moba"] .bn-toolbar .mantine-Button-root,
.bn-container[data-theming="moba-mini"] .bn-toolbar .mantine-Button-root {
  padding: 0 8px;
}

.bn-container[data-theming="moba"] .bn-toolbar button .mantine-ActionIcon-icon svg,
.bn-container[data-theming="moba-mini"] .bn-toolbar button .mantine-ActionIcon-icon svg {
  width: 16px;
  height: 16px;
}

.bn-container[data-theming="moba"] .bn-drag-handle-menu.bn-menu-dropdown,
.bn-container[data-theming="moba-mini"] .bn-drag-handle-menu.bn-menu-dropdown {
  padding: 0px;
}

.bn-container[data-theming="moba"] .bn-tooltip,
.bn-container[data-theming="moba-mini"] .bn-tooltip {
  padding: 4px 6px;
  font-weight: 500;
  border: none;
}

.bn-container[data-theming="moba"] .bn-tooltip p:last-of-type,
.bn-container[data-theming="moba-mini"] .bn-tooltip p:last-of-type {
  font-weight: 400;
  color: rgba(171, 173, 173, 1);
}

.bn-container[data-theming="moba"] .bn-tooltip p:first-of-type,
.bn-container[data-theming="moba-mini"] .bn-tooltip p:first-of-type {
  font-weight: 500;
  color: rgba(217, 217, 217, 1);
}

.bn-container[data-theming="moba"] .bn-menu-dropdown::-webkit-scrollbar,
.bn-container[data-theming="moba-mini"] .bn-menu-dropdown::-webkit-scrollbar {
  width: 6px;
  margin: 0 4px;
}

.bn-container[data-theming="moba"] .bn-menu-dropdown::-webkit-scrollbar-thumb,
.bn-container[data-theming="moba-mini"] .bn-menu-dropdown::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.16);
  border-radius: 6px;
}

.bn-container[data-theming="moba"] .bn-menu-dropdown::-webkit-scrollbar-track,
.bn-container[data-theming="moba-mini"] .bn-menu-dropdown::-webkit-scrollbar-track {
  background: transparent;
}

.bn-container[data-theming="moba"] .bn-suggestion-menu,
.bn-container[data-theming="moba-mini"] .bn-suggestion-menu {
  padding: 0;
}

.bn-container[data-theming="moba"]
  .bn-block-group
  .bn-block-group
  .bn-block-outer:not([data-prev-depth-changed]):before,
.bn-container[data-theming="moba-mini"]
  .bn-block-group
  .bn-block-group
  .bn-block-outer:not([data-prev-depth-changed]):before {
  border-left: none;
}

.bn-container[data-theming="moba"] .bn-block-content[data-content-type="numberedListItem"],
.bn-container[data-theming="moba-mini"] .bn-block-content[data-content-type="numberedListItem"] {
  gap: 4px;
}

.bn-container[data-theming="moba"]
  .bn-block-outer:not([data-prev-type])
  > .bn-block
  > .bn-block-content[data-content-type="numberedListItem"]:before,
.bn-container[data-theming="moba-mini"]
  .bn-block-outer:not([data-prev-type])
  > .bn-block
  > .bn-block-content[data-content-type="numberedListItem"]:before {
  min-width: 24px;
  display: flex;
  justify-content: center;
}

.bn-container[data-theming="moba"] .bn-block-content[data-content-type="bulletListItem"],
.bn-container[data-theming="moba-mini"] .bn-block-content[data-content-type="bulletListItem"] {
  gap: 4px;
}

.bn-container[data-theming="moba"] .bn-block-content[data-content-type="bulletListItem"]:before,
.bn-container[data-theming="moba-mini"]
  .bn-block-content[data-content-type="bulletListItem"]:before {
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Bullet styles */
.bn-container[data-theming="moba"]
  .bn-block-content:not([data-content-type="bulletListItem"])
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  > .bn-block-content[data-content-type="bulletListItem"]:before,
.bn-container[data-theming="moba-mini"]
  .bn-block-content:not([data-content-type="bulletListItem"])
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  > .bn-block-content[data-content-type="bulletListItem"]:before {
  content: "•";
}

.bn-container[data-theming="moba"]
  .bn-block-content:not([data-content-type="bulletListItem"])
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  [data-content-type="bulletListItem"]
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  > .bn-block-content[data-content-type="bulletListItem"]:before,
.bn-container[data-theming="moba-mini"]
  .bn-block-content:not([data-content-type="bulletListItem"])
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  [data-content-type="bulletListItem"]
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  > .bn-block-content[data-content-type="bulletListItem"]:before {
  content: "◦";
}

.bn-container[data-theming="moba"]
  [data-content-type="bulletListItem"]
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  > .bn-block-content[data-content-type="bulletListItem"]
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  > .bn-block-content[data-content-type="bulletListItem"]:before,
.bn-container[data-theming="moba-mini"]
  [data-content-type="bulletListItem"]
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  > .bn-block-content[data-content-type="bulletListItem"]
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  > .bn-block-content[data-content-type="bulletListItem"]:before {
  font-size: 10px;
}

.bn-container[data-theming="moba"]
  .bn-block-content:not([data-content-type="bulletListItem"])
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  [data-content-type="bulletListItem"]
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  > .bn-block-content[data-content-type="bulletListItem"]
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  > .bn-block-content[data-content-type="bulletListItem"]:before,
.bn-container[data-theming="moba-mini"]
  .bn-block-content:not([data-content-type="bulletListItem"])
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  [data-content-type="bulletListItem"]
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  > .bn-block-content[data-content-type="bulletListItem"]
  ~ .bn-block-group
  > .bn-block-outer:not([data-prev-type])
  > .bn-block
  > .bn-block-content[data-content-type="bulletListItem"]:before {
  content: "▪";
}

/* 각 테마별 스타일 */

/* moba 전용 스타일 */
.bn-container[data-theming="moba"] .bn-block-content[data-content-type="heading"][data-level="1"] {
  font-size: 30px;
}

.bn-container[data-theming="moba"] .bn-side-menu[data-block-type="heading"][data-level="1"] {
  height: 50px;
}
.bn-container[data-theming="moba"] .bn-block-content[data-content-type="heading"][data-level="2"] {
  font-size: 24px;
}

.bn-container[data-theming="moba"] .bn-side-menu[data-block-type="heading"][data-level="2"] {
  height: 42px;
}

.bn-container[data-theming="moba"] .bn-block-content[data-content-type="heading"][data-level="3"] {
  font-size: 20px;
}

.bn-container[data-theming="moba"] .bn-side-menu[data-block-type="heading"][data-level="3"] {
  height: 36px;
}

/* moba-mini 전용 스타일 */
[data-theming="moba-mini"] h1 {
  font-size: 22px;
}

.bn-container[data-theming="moba-mini"] .bn-side-menu[data-block-type="heading"][data-level="1"] {
  height: 33px;
}

[data-theming="moba-mini"] h2 {
  font-size: 20px;
}

.bn-container[data-theming="moba-mini"] .bn-side-menu[data-block-type="heading"][data-level="2"] {
  height: 30px;
}

[data-theming="moba-mini"] h3 {
  font-size: 18px;
}

.bn-container[data-theming="moba-mini"] .bn-side-menu[data-block-type="heading"][data-level="3"] {
  height: 27px;
}

/* Side Menu */
bn-side-menu .mantine-UnstyledButton-root:not(.mantine-Menu-item) svg {
  width: 14px;
  height: 14px;
}

[data-theming="moba-mini"] .bn-side-menu {
  height: 27px;
}

.bn-container[data-theming-moba] .bn-side-menu .mantine-ActionIcon-icon:not(.mantine-Menu-item) {
  width: 16px;
  height: 16px;
}

.bn-side-menu .mantine-ActionIcon-root:not(.mantine-Menu-item) {
  width: 16px !important;
  height: 16px !important;
}

/* Command (suggestion) menu */

.bn-container[data-theming="moba-mini"] .bn-suggestion-menu {
  overflow-y: scroll;
  scrollbar-width: none;
}

[data-theming="moba"] button.bn-button {
  user-select: none; /* 텍스트 선택 방지 */
  -webkit-user-drag: element; /* Safari를 위한 드래그 활성화 */
}

/* 드래그 시 삽입되는 위치 */
.prosemirror-dropcursor-block {
  background: var(--w-16, rgba(255, 255, 255, 0.16)) !important;
  z-index: 999;
  height: 2px !important;
  border-radius: 5px;
}

/* 드래그 시 보이는 미리보기 뷰 */
.bn-drag-preview {
  color: white;
  /* visibility: hidden; */
  width: 792px;
  font-size: 14px;
}

.bn-inline-content {
  line-break: anywhere;
}

::selection {
  background: white !important;
  color: initial;
}
