.container {
  height: calc(100% - 1px);
  background: #242626;
  border-radius: 5px;
}

.wrap {
  height: 100%;
  padding: 3px 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 5px;
  container: eventWrapper / inline-size;
  position: relative;
}
.wrap .eventBorder {
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 1px solid transparent;
  border-radius: 5px;
  opacity: 0.2;
}

.checkbox {
  min-width: 14px;
  min-height: 14px;
  border-radius: 3px;
  box-shadow: 0 0 0 1px white inset;
  background-color: #1a1c1c;
  position: relative;
}

.checkbox:hover::before,
.checkbox.checkboxCompleted:not(.animationActive)::before {
  box-sizing: border-box;
  content: "";
  position: absolute;
  top: 35%;
  left: 15%;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  transform-origin: 0% 100%;
  width: 5px;
  height: 9px;
  border-color: #1a1c1c;
  transform: translate3d(0, -0.5em, 0) rotate(45deg);
}

.checkbox.checkboxCompleted.animationActive {
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}

.checkbox.checkboxCompleted.animationActive::before {
  box-sizing: border-box;
  content: "";
  position: absolute;
  top: 35%;
  left: 15%;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}

.meetLinkWrapper {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  margin: 2px 0;
}

.meetLinkWrapper:hover .blockLinkIcon {
  display: flex;
}

.meet {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 14px;
  min-height: 14px;
  border-radius: 3px;
  background-image: url(../../assets/Main/icon-meet.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.contents {
  flex: 1;
  overflow: hidden;
  gap: 4px;
}

.title {
  font-family: "Pretendard Variable";
  font-weight: 400;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.4;
  position: relative;
}

.guests {
  min-height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* position: relative; */
}
.guests:hover .guests-info {
  visibility: visible;
}

.guests-1 {
  width: 16px;
  background-image: url(../../assets/Main/guest_01.png);
}
.guests-2 {
  width: 27px;
  background-image: url(../../assets/Main/guest_02.png);
}
.guests-3 {
  width: 38px;
  background-image: url(../../assets/Main/guest_03.png);
}
.guests-many {
  width: 48px;
  background-image: url(../../assets/Main/guest_many.png);
}
.guests-many-month {
  width: 26px;
  background-image: url(../../assets/Main/guest_many_month.png);
}

.guests-info {
  height: fit-content;
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 6px 8px;
  gap: 2px;
  border-radius: 4px;
  z-index: 1000;
  top: 26px;
  visibility: hidden;
  user-select: none;
}

.guests-info-item {
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  color: black;
}

.integrationWrapper {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
}
.integrationWrapper:hover .blockLinkIcon {
  display: flex;
}
.blockLinkIcon {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 14px;
  height: 14px;
  top: 0;
  left: 0;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  z-index: 100;
}
.integration {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 14px;
  min-height: 14px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.integration-gmail {
  background-image: url(../../assets/Main/gmail-icon.svg);
}

.integration-jira {
  background-image: url(../../assets/Main/jira-icon.svg);
}

.integration-slack {
  background-image: url(../../assets/Main/slack-icon.svg);
}

/* .body-icon-hover{
    visibility:hidden;
    position:absolute;
    background:white;
    color:black;
    font-size:11px;
    font-weight: 500;
    border-radius: 4px;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
    padding:3px 7px;
    line-height: 14.3px;
    z-index: 1;
    width: max-content;
    max-width: 150px;
    word-break: break-word;
    transform: translate(20px, -2px);
}

.body-icon-hover::before {
    visibility: hidden;
    content: "";
    width: 7px;
    height: 10px;
    position: absolute;
    background: white;
    z-index: 2;
    transform: translate(-10px,1px) rotate(45deg);
    border-radius: 1px;
} */
.iconForVisibility {
  display: block;
  width: 14px;
  height: 14px;
}

.smallEvent {
  height: 19px;
  min-height: 19px;
}
.smallEvent .wrap {
  padding: 0.5px 6px;
  align-items: center;
}
.smallEvent .title {
  font-size: 13px;
  line-height: 1.38;
}

.mediumEvent {
  height: 19px;
  box-sizing: border-box;
}

.mediumEvent .title {
  line-height: 1.38;
}

.mediumEvent .wrap {
  align-items: center;
}

.largeEvent .wrap {
  padding: 4px 6px;
}
.largeEvent .wrap {
  line-height: 1.2;
}

.largeEvent .title {
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.timeDuration {
  font-size: 10px;
  line-height: 1.3;
  opacity: 0.4;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.container.eventDone .eventBorder {
  opacity: 0.2;
}
.priorityButton {
  width: 14px;
  height: 14px;
}

.container.eventDone .priorityButton,
.container.eventDone .meetLinkWrapper,
.container.eventDone .checkbox,
.container.eventDone .contents,
.container.eventDone .iconForVisibility,
.container.eventDone .integrationWrapper {
  opacity: 0.6;
}
.container.eventDone .title {
  opacity: 0.6;
  text-decoration: line-through;
}

.container.notDoneTask .eventBorder {
  animation: reminder 0.8s infinite alternate;
}

.container.meetWithContainer .eventBorder {
  opacity: 0.08;
}
@container eventWrapper (max-width: 85px) {
  .priorityButton,
  .iconForVisibility,
  .integrationWrapper {
    display: none;
  }
}
@container eventWrapper (max-width: 36px) {
  .largeEvent .title,
  .title,
  .timeDuration {
    display: none;
  }
}
@container eventWrapper (max-width: 14px) {
  .checkbox,
  .meetLinkWrapper {
    display: none;
  }
}

@keyframes reminder {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #1a1c1c;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 5px;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 5px;
    height: 9px;
    border-color: #1a1c1c;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}
