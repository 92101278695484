.modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 10000;
}

.settings {
  display: flex;
  width: 80%;
  height: 80%;
  border-radius: 6px;
  border: 1px solid #545959;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

@media (max-width: 800px) {
  .settings {
    width: 100%;
    height: 100%;
  }
}

@media (max-height: 400px) {
  .settings {
    width: 100%;
    height: 100%;
  }
}

.settings__contents__area::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 4px;
}

.settings__contents__area::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(255, 255, 255, 0);
}

.settings__contents__area:hover::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.2);
}

.settings__contents__area::-webkit-scrollbar-track {
  background: #363a3a;
}

.settings__contents__area {
  display: flex;
  flex-direction: column;
  background: #363a3a;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 35px;
  padding-bottom: 35px;
  overflow-y: scroll;
  box-sizing: border-box;
}

.settings__close {
  position: fixed;
  top: 15px;
  right: 15px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.settings__close img {
  width: 20px;
  height: 20px;
}

.settings__area {
  display: flex;
  flex-direction: column;
  background: #414444;
  width: 220px;
  height: 100%;
  flex-shrink: 0;
  padding-left: 30px;
  padding-top: 30px;
  box-sizing: border-box;
}

.settings__title {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
}

.settings__menu__title {
  display: flex;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
}

.settings__menu__title__selected {
  display: flex;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 8px;
  color: #69e4ff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
}
