.integrations__contents {
  display: flex;
  flex-direction: column;
  background: #363a3a;
  cursor: pointer;
}

.integrations__contents .title {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.integrations__contents .description {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  padding-top: 20px;
}

.my__connections__area {
  display: flex;
  flex-direction: row;
  padding-top: 40px;
  flex-wrap: wrap;
  gap: 30px;
}

.my__connection__item {
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 20px;
  width: 280px;
  height: 80px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.my__connection__item:hover .my__connection__delete {
  display: block;
}

.my__connection__item__Icon {
  width: 40px;
  height: 40px;
  margin-right: 21px;
  margin-left: 20px;
}

.my__connection__info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
}

.my__connection__title {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.4;
}

.my__connection__email {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.my__connection__delete {
  display: none;
  position: relative;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 18px;
  height: 100%;
}

.my__connection__delete__Icon {
  width: 18px;
  height: 18px;
}

.connections {
  display: flex;
  flex-direction: row;
  padding-top: 28px;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 30px;
}

.line {
  height: 1px;
  opacity: 0.2;
  background: #fff;
  margin: 60px 0px 30px 0px;
}

.connections__item {
  display: flex;
  align-items: center;
  border-radius: 20px;
  width: 280px;
  height: 60px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.connections__item img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  margin-left: 10px;
}

.connections__title {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  flex-grow: 1;
}
