.repeatModalWrapper {
  position: absolute;
  top: 5px;
}

.setting-property-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.setting-property-btn > span {
  pointer-events: none;
}

.setting-property-icon {
  width: 16px;
  height: 16px;
  opacity: 0.5;

  background-position: center;
  background-repeat: no-repeat;
  background-size: inherit;
}

.wrap {
  position: absolute;
  display: flex;

  width: 171px;
  border-radius: 6px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--task-task_dropdown, #2f3131);

  box-sizing: border-box;
  z-index: 1000;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  animation: modalFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.wrap::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  border: 7px solid #363a3a;
}

.wrap::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 20px;
}

.wrap::-webkit-scrollbar-track {
  background: #363a3a;
}

.repeat-row--focused {
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  outline: none;
}

.repeat-row {
  display: flex;
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0px 5px;
  color: white;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.repeat-row:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100%);
  height: 100%;
  overflow: hidden;
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  z-index: -1;
  animation: hoverFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  opacity: 0;
}

@keyframes hoverFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.projectRow-add {
  width: 100%;
  min-height: 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  gap: 10px;
  margin-left: 10px;
  position: relative;
  gap: 7px;
  color: white;
}

.projectRow-add-icon {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  border: 2px solid rgba(171, 173, 173, 0.4);
  text-align: center;
  line-height: 14px;
}

.projectRow-add-title {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.4;
}

.setting-property-label {
  margin-right: 5px;
  font-size: 12px;
  pointer-events: none;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
  flex-basis: 0;
  flex-grow: 2;
  flex-shrink: 1;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.4;
}

.label-on {
  color: white;
  pointer-events: none;
  opacity: 1;
}

.label-disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.repeat_btn {
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.repeat_btn:hover {
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.repeat_icon {
  fill: #abadad;
}

.repeat_btn:hover .repeat_icon {
  fill: #fff;
}

.repeat-row-list {
  display: flex;
  align-items: center;
  gap: 5px;
}
.checkIcon {
  display: flex;
  align-self: center;
}

.checkIcon {
  fill: transparent;
}

.checkIcon--checked {
  fill: #fff;
}

.repeat-row-list > span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;

  overflow: hidden;
  color: var(--whtie, #fff);
  text-overflow: ellipsis;

  /* body-s */
  font-family: "Pretendard Variable";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.12px;
}
