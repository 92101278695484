.react-datepicker {
  /* width:300px; */
  /* height:300px; */
  border-radius: 6px;
  border: 1px solid rgba(84, 89, 89, 1);
}

.react-datepicker__month-container {
  background: rgba(54, 58, 58, 1);
}

.react-datepicker__navigation {
  top: 0px;
  height: 53px;
}

.react-datepicker__header {
  background-color: rgba(54, 58, 58, 1);
  border: none;
}

.react-datepicker__navigation--previous {
  left: 72px;
}
.react-datepicker__navigation--next {
  right: 72px;
}
.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  height: 4px;
  top: 9px;
  width: 4px;
}

.react-datepicker__current-month {
  display: flex;
  width: 156px;
  min-height: 38px;
  border: 1px solid rgba(84, 89, 89, 1);
  border-radius: 23px;
  margin: auto;
  color: white;
  font-family: "Pretendard Variable";
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 7px;
  color: white;
  font-family: "Montserrat Variable";
  font-size: 12px;
  font-weight: 500;
}

.react-datepicker__day:hover,
.react-datepicker__day--keyboard-selected {
  color: white;
  background: rgba(107, 220, 255, 0.4);
  border-radius: 100%;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background: rgba(105, 228, 255, 0.4);
  border-radius: 100%;
}

.react-datepicker__day--selected {
  color: rgba(54, 58, 58, 1);
  background: rgba(107, 220, 255, 1);
  border-radius: 100%;
}

.react-datepicker__day--selected:hover {
  background: rgba(107, 220, 255, 0.4);
}

.datepickerTimeWrap .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  display: none;
}

.datepickerTimeWrap .react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: none;
  border: none;
}

.datepickerTimeWrap
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 79px;
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 6px;

  background: var(--task-task_dropdown, #2f3131);
  color: white;
  border-radius: 6px;
}

.datepickerTimeWrap
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  overflow-y: scroll;
  width: 79px;
  height: 170px;
}

.datepickerTimeWrap
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list::-webkit-scrollbar {
  display: none;
}

.datepickerTimeWrap
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  display: flex;
  height: 26px;
  padding-top: 5px;
  padding-bottom: 3px;
  white-space: nowrap;
  font-family: "Pretendard Variable";
  font-size: 12px;
  font-weight: 400;
  justify-content: left;
  align-items: center;
  box-sizing: border-box;
}

.datepickerTimeWrap
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.datepickerTimeWrap
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: var(--task-task_dropdown, #2f3131);
  color: var(--moba, #69e4ff);
  font-weight: 400;
}

.datepickerTimeWrap .react-datepicker__time-list-item:hover {
  background: red;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: #7c7d7d;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #7c7d7d;
}

.react-datepicker--time-only {
  background: var(--task-task_dropdown, #2f3131);
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.react-datepicker__time-container {
  width: 100%;
  user-select: none;
}
