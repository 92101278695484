.wiki__body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  background: #1a1c1c;
  color: white;
  align-items: center;
}

.wiki__header {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: end;
  margin-top: 21px;
  padding-right: 30px;
  margin-bottom: 21px;
}

.headerCreateProject {
  display: flex;
  margin-left: 15px;
  padding: 10px 18px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 23px;
  border: 1px solid #fff;
  background: #1a1c1c;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  cursor: pointer;
}

.headerCreateProject:hover {
  border-radius: 23px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.08);
}

.headerInprogessAndDoneArea {
  display: flex;
  margin-left: auto;
  align-items: flex-start;
  height: 38px;
  padding: 0px 20px;
  align-items: center;
  gap: 15px;
  border-radius: 23px;
  border: 1px solid #545959;
  cursor: pointer;
}

.title {
  color: #7c7d7d;
  leading-trim: both;
  text-edge: cap;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
}

.title:hover {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
}

.titleSelected {
  color: var(--whtie, #fff);
  leading-trim: both;
  text-edge: cap;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
}

.project__empty__body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer;
}

.empty__icon {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  user-select: none;
  pointer-events: none;
}

.empty__div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty__description {
  margin-top: 30px;
  color: #7c7d7d;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
}

.project__body {
  display: flex;
  flex-grow: 1;
  height: calc(100vh - 80px);
  background: #1a1c1c;
  color: white;
  align-self: flex-start;
  padding-top: 30px;
  box-sizing: border-box;
  width: 100%;
}

.project__container {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  overflow-y: auto;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  align-content: flex-start;
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 50px;
}

@media (max-width: 1260px) {
  .project__container {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
}

@media (max-width: 900px) {
  .project__container {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }
}

.project__container::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 4px;
}

.project__container::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(255, 255, 255, 0);
}

.project__container:hover::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.2);
}

.project__container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

.project__item {
  height: 240px;
  max-height: 240px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 2px solid #667eff;
  background: rgba(91, 92, 92, 0.15);
  padding-top: 26px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  cursor: pointer;
}

.project__item .title {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.project__item .description {
  display: block;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
