.calendar__contents {
  display: flex;
  flex-direction: column;
  background: #363a3a;
  cursor: pointer;
}

.calendar__contents .title {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 35px;
}

.calendar__contents .description {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.calendar__setting__list {
  display: flex;
  flex-direction: column;
  height: 220px;
  border-width: 1px;
  border: var(--hover, rgba(255, 255, 255, 0.08));
  align-self: stretch;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.calendar__setting__item {
  padding: 10px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar__setting__item::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.08);
  left: 0;
  bottom: -3px;
}

.switch__container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: max-content;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.2);
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;
  padding: 4px;
}

.switch__item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #7c7d7d;
  color: #7c7d7d;
  padding: 5px 10px;
  border-radius: 5px;
  text-wrap: nowrap;
  transition:
    color 0.3s,
    background-color 0.3s;
  user-select: none;
}

.switch__item:hover {
  color: #fff;
  cursor: pointer;
}

.switch__item:hover svg path {
  fill: #fff !important;
  cursor: pointer;
  transition: fill 0.3s;
}

.switch__text {
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.12px;
}

.selected {
  color: #fff;
  background: rgba(255, 255, 255, 0.15);
  transition:
    color 0.3s,
    background-color 0.3s;
  z-index: 999;
  fill: #fff;
}
