.wrap {
  width: 100%;
  height: 100vh;
  background: radial-gradient(50% 50% at 50% 50%, #5fcbe3 -80%, rgba(95, 203, 227, 0) 100%), #363a3a;
  background-size: 120% 70%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  animation: loginBackAni 10000ms linear infinite;
}

.body {
  position: relative;
  display: flex;
  width: 100%;
  height: 66%;
  top: 0;
  background: #363a3a;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: end;
  gap: 30%;
}

@keyframes loginBackAni {
  0%,
  100% {
    background-position: 50% 50%;
  }
  50% {
    background-position: 50% 30%;
  }
}

.joinWaitListArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
}

.title {
  color: #69e4ff;
  text-align: center;
  font-family: "Montserrat Variable";
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 12px;
  margin-bottom: 23px;
}

.description {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 23px;
  margin-bottom: 98px;
}

.joinWaitListBtn {
  display: flex;
  position: absolute;
  height: 24px;
  padding: 18px 38px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #69e4ff;
  background: linear-gradient(180deg, #69e4ff 29.17%, #1eaac8 100%);
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset;
  color: #000;
  font-family: "Montserrat Variable";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
  top: 66%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.joinWaitListBtn:hover {
  border-radius: 100px;
  border: 1px solid var(--MOBA-Key, #69e4ff);
  background: linear-gradient(180deg, #aae9f7 29.17%, #69e4ff 100%);
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset;
}

.welcomeContents {
  color: #69e4ff;
  text-align: center;
  font-family: "Montserrat Variable";
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
