/* .noteMini {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  margin-top: 2px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  flex: 1 0 0;

  overflow: hidden;
  color: var(--project-default-grey, #abadad);
  text-overflow: ellipsis;

  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
} */

.note {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  pointer-events: all;
  user-select: all;
}

.note-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.note-input:focus {
  outline: none;
}

.note-input {
  width: calc(100% - 20px);
  height: 100%;
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-weight: 400;
  background: rgba(36, 38, 38, 1);
  border: none;
  color: rgba(255, 255, 255, 0.4);
  resize: none;
}

.note-view {
  max-height: 96px;
  min-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  width: calc(100% - 20px);
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-weight: 400;
  background: rgba(36, 38, 38, 1);
  border: none;
  color: #abadad !important;
  resize: none;
  opacity: 0.5;
}

.slash_menu {
  width: 160px;
  z-index: 9999;
  background-color: rgba(47, 49, 49, 1);
  color: rgba(217, 217, 217, 1);
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  top: 8px;
  overflow: hidden;
}

.slash_menu_item {
  background-color: rgba(47, 49, 49, 1);
  cursor: pointer;
  font-size: 12px;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 10px 8px;
  gap: 8px;
}

.slash_menu_item_selected {
  background-color: rgba(255, 255, 255, 0.08);
}

.slash_menu_icon {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(171, 173, 173, 1);
}
