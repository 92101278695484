.project__item {
  height: 207px;
  max-height: 207px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #667eff;
  background: rgba(91, 92, 92, 0.15);
  padding-top: 20px;
  padding-bottom: 0px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.projectTitleDescriptionArea {
  height: 107px;
}

.projectTitleDescriptionArea .project__title {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}

.projectTitleDescriptionArea .project__description {
  display: block;
  color: #abadad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-left: 20px;
  padding-right: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 3;
  -moz-line-clamp: 3;
  -ms-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
}

.project__bookmark__area {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.setting-property-resource {
  gap: 15px;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 35px;
}

.resource-item {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid rgba(84, 89, 89, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
}

.resource-item-delete {
  position: absolute;
  top: -7px;
  right: -7px;
  cursor: pointer;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 17px;
  background: #383a3a;
  border: 1px solid #abadad;
  border-radius: 100%;
  font-size: 8px;
  font-weight: 700;
  cursor: pointer;
  opacity: 0;
  transition: opacity 280ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.resource-item:has(.resource-item-favicon) {
  background: rgba(54, 58, 58, 1);
}

.resource-item:has(.resource-item-text):hover,
.resource-item:has(.resource-item-text):hover .resource-item-text {
  color: white;
  border-color: white;
}

.resource-item-favicon {
  width: 17.4px;
  height: 17.4px;
  border: none;
  line-height: 22px;
  background-size: contain;
}

.bookmarkAddArea {
  display: flex;
  width: 46px;
  height: 46px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #545959;
  background: #242626;
  cursor: pointer;
}

.bookmarkAddIcon {
  width: 10px;
  height: 10px;
}
