.onboardingBackground {
  visibility: hidden;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), #1a1c1c;
  padding-bottom: 51px;
  transform: translateY(-100%);
}

.mount {
  animation: 0.3s ease-in-out loadEffect forwards;
}

@keyframes loadEffect {
  0% {
    visibility: visible;
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
  }
}

.unmount {
  animation: 0.3s ease-in-out unloadEffect forwards;
}

@keyframes unloadEffect {
  0% {
    visibility: visible;
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    visibility: visible;
    transform: translateY(-100%);
    opacity: 0;
  }
}

.progressBar {
  width: 400px;
  height: 4px;
  background-color: #363a3a;
  border-radius: 5px;
  margin-bottom: 47px;
}

.progress {
  width: 50px;
  height: 4px;
  background-color: #69e4ff;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}

.welcomeCard {
  display: flex;
  position: relative;
  width: 900px;
  height: 500px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 2px solid #363a3a;
  background: #1a1c1c;

  background: radial-gradient(50% 50% at 50% 50%, #5fcbe3 -170%, rgba(95, 203, 227, 0) 100%),
    rgba(26, 28, 28, 1);
  background-size: 120% 70%;
  background-repeat: no-repeat;
  background-position: 50% 250%;
  justify-content: center;
}

.dollorImgArea {
  text-align: center;
}

.dollorImg {
  padding-top: 78px;
  width: 322px;
  height: 56px;
}

.textArea {
  top: 100px;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.believer {
  color: #69e4ff;
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  line-height: 140%;
}

.moba {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.hello {
  color: #abadad;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
}

.continue {
  display: flex;
  width: 230px;
  height: 40px;
  margin-top: 40px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #51a8bb;
  background: #1a1c1c;
  color: #69e4ff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.onboardingCard {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 900px;
  height: 500px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 2px solid #363a3a;
  background: #1a1c1c;
  justify-content: center;
  padding-left: 70px;
  padding-top: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  box-sizing: border-box;
  gap: 25px;
}

.onboardingGuide {
  display: flex;
  flex-direction: column;
  width: 330px;
  height: 380px;
  margin-top: 45px;
  margin-bottom: 25px;
  justify-content: space-between;
}

.heaaderTitleArea {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mainHeader {
  color: #69e4ff;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  white-space: pre-line;
}

.subHeader {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  white-space: pre-line;
}

.description {
  color: #abadad;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  white-space: pre-line;
}

.lottieContainer {
  display: flex;
  width: 450px;
  height: 450px;
}

.btnArea {
  display: flex;
  width: 160px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.nextBtn {
  display: flex;
  width: 160px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #51a8bb;
  background: #1a1c1c;
  color: #69e4ff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
}

.previousArea {
  display: flex;
  align-items: center;
}

.previousArrowIcon {
  width: 16px;
  height: 16px;
}

.previousBtn {
  color: #7c7d7d;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  cursor: pointer;
}

.welcomeCompleteCard {
  display: flex;
  position: relative;
  width: 900px;
  height: 500px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 2px solid #363a3a;
  background: #1a1c1c;

  background: radial-gradient(50% 50% at 50% 50%, #5fcbe3 -170%, rgba(95, 203, 227, 0) 100%),
    rgba(26, 28, 28, 1);
  background-size: 120% 70%;
  background-repeat: no-repeat;
  background-position: 50% -150%;
  justify-content: center;
  box-sizing: border-box;
  padding-left: 70px;
  padding-top: 70px;
  padding-right: 70px;
  padding-bottom: 50px;
  justify-content: space-between;
}

.completeGuide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.completeBtnArea {
  display: flex;
  width: 190px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 100px;
  border: 1px solid var(--MOBA-Key, #69e4ff);
  background: linear-gradient(180deg, #69e4ff 29.17%, #1eaac8 100%);
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset;
}

.playmobaBtn {
  display: flex;
  width: 190px;
  height: 60px;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
  border-radius: 100px;
  border: 1px solid var(--MOBA-Key, #69e4ff);
  background: linear-gradient(180deg, #69e4ff 29.17%, #1eaac8 100%);
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.connectionContainer {
  display: flex;
  width: 340px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
}

.bookMeeting {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  white-space: pre-line;
  margin-top: 10px;
}

.divider {
  margin-top: 30px;
  width: 100%;
  height: 1px;
  background-color: #7c7d7d;
}

.bookMeetingBtn {
  display: flex;
  width: 200px;
  height: 40px;
  justify-content: center;
  gap: 10px;
  flex-shrink: 0;
  color: #69e4ff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border-radius: 6px;
  border: 1px solid #51a8bb;
  background: #1a1c1c;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

.bookmetingSlackArea {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}

.slack {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 10px;
  right: 0px;
}

.slackIcon {
  width: 18px;
  height: 18px;
}
