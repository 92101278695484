@import "reset.css";
@import url("./fonts/montserratvariable.css");
@import url("./fonts/pretendardvariable.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

/* @font-face {
  font-family: '';
  src: url('./assets/fonts/');
  src: url('./assets/fonts/') format("embedded-opentype"),
  url('./assets/fonts/') format("woff2"),
  url('./assets/fonts/') format("woff");
  font-weight: 200;
  font-style: normal
} */

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "Pretendard Variable";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body:after {
  display: block;
  overflow: hidden;
  width: 0;
  height: 0;
}

button {
  all: unset;
  cursor: pointer;
}

button:focus {
  outline: revert;
}
