.wrap {
  position: fixed;
  display: flex;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  z-index: 111111;
}

.main {
  width: 340px;
  height: 220px;
  background: rgba(54, 58, 58, 1);
  border: 1px solid rgba(84, 89, 89, 1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  animation: fadeIn 250ms cubic-bezier(0, 1, 0, 1) forwards;
}

@keyframes fadeIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.header {
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.header span {
  margin: 0px 13px;
}

.header-close {
  cursor: pointer;
}

.body {
  width: 300px;
  height: 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}

.body-icon {
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 16px;
}

.body-input {
  background: rgba(38, 41, 41, 1);
  width: calc(264px - 16px);
  height: 100%;
  border: none;
  caret-color: rgba(107 220 255);
  text-overflow: ellipsis;
  border-radius: 6px;
  color: white;
  padding: 0px 8px;
  text-overflow: ellipsis;
}

/* .body-input::placeholder { color: rgba(171, 173, 173, .4); } */
.body-input:focus {
  outline: none;
  /* animation:inputActive 100ms cubic-bezier(0, 0, 1, 1) forwards; */
  color: white;
}

.footer {
  width: 300px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(84, 89, 89, 1);
  border-radius: 6px;
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: white;
  line-height: 40px;
  cursor: pointer;
}
