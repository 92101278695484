.wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(50% 50% at 50% 50%, #5fcbe3 -80%, rgba(95, 203, 227, 0) 100%),
    rgba(26, 28, 28, 1);
  background-size: 120% 70%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  animation: loginBackAni 10000ms linear infinite;
}

@keyframes loginBackAni {
  0%,
  100% {
    background-position: 50% 50%;
  }
  50% {
    background-position: 50% 30%;
  }
}

.body {
  position: absolute;
  display: flex;
  width: 100%;
  height: 66%;
  top: 0;
  background: rgba(26, 28, 28, 1);
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.title {
  display: flex;
  font-family: "Montserrat Variable";
  font-size: 24px;
  font-weight: 500;
  /* line-height: 29px; */
  text-align: center;
  color: rgba(105, 228, 255, 1);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-btn {
  display: flex;
  width: 300px;
  height: 43px;
  /* top: 380px; */
  /* left: 570px; */
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(84, 89, 89, 1);
  gap: 10px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.login-icon {
  width: 16px;
  height: 16px;
  background: url("../../assets/Login/signup-google-icon.svg");
  background-size: inherit;
  background-repeat: no-repeat;
  background-position: center;
}

.login-text {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: white;
  user-select: none;
}

/* 팝업 */

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  box-sizing: border-box;
}

.popup {
  display: flex;
  position: relative;
  width: 340px;
  height: 142px;
  color: white;
  border-radius: 10px;
  border: 1px solid #545959;
  background: #363a3a;
  justify-content: center;
  align-items: center;
}

.closeButton {
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  position: absolute;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  background: url(../../assets/Login/login-popup-close.svg);
  background-size: inherit;
  background-repeat: no-repeat;
  background-position: center;
}

.popupBody {
  padding-left: 23px;
  padding-right: 23px;
  white-space: pre-line;
}

.popupBody p {
  margin: 10px 0;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.popupBody a {
  color: #69e4ff;
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.popupBody a:hover {
  text-decoration: underline;
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: #f2f2f2;
  background-image: none;
  border: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: "Roboto", arial, sans-serif;
  font-weight: 500;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
}

.gsi-material-button:disabled .gsi-material-button-state {
  background-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #001d35;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #001d35;
  opacity: 8%;
}
