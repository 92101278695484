/* .modalNote {
  display: flex;
  padding: 10px 0px 10px 34px;
} */

.property--note {
  display: flex;
  min-height: 30px;
  height: max-content;
  /* gap: 6px; */
  align-items: flex-start;
  font-family: "Pretendard Variable";
  padding: 0px 2px 10px 10px;
}

.property__empty_title {
  flex: 1 0 0;
  box-sizing: border-box;
  display: flex;
  height: 30px;
  padding: 0px 4px;
  align-items: center;
  border-radius: 5px;

  color: var(--placeholder, #7c7d7d);
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}

.property__empty_title:hover {
  border-radius: 5px;
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.property__empty_title > span {
  line-height: 22px;
}

.property--note__contents {
  width: 100%;
}

.setting-note-mini {
  height: fit-content;
  display: flex;
  padding: 4px 4px;
  max-height: 63px;
  align-items: flex-start;
  flex: 1 0 0;
  word-break: break-all;
  overflow: hidden;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  color: var(--project-default-grey, #abadad) !important;
  text-overflow: ellipsis;

  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  white-space: pre-wrap;
  cursor: pointer;

  &:hover {
    border-radius: 5px;
    background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  }
}

.setting-note {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  padding: 15px 0px 15px 0px;

  overflow-x: visible;

  flex: 1 0 0;

  scrollbar-width: none;
  text-overflow: ellipsis;

  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
