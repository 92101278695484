.guide__message__area {
  display: flex;
  position: absolute;
  flex-direction: row;
  margin-top: 64px;
  box-sizing: border-box;
  align-items: center;
}

.guide__message__box {
  display: inline-flex;
  width: 380px;
  padding: 18px 25px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #545959;
  background: #242626;
  color: #7c7d7d;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  box-sizing: border-box;
}
