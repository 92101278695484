.main {
  display: flex;
  width: 100dvw;

  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
}

@media (max-width: 800px) {
  .main {
    overflow-x: scroll;
  }
}
