.property--video {
  display: flex;
  width: 100%;
  height: 30px;
  /* gap: 6px; 타이틀과 구글링크 사이의 간격 */
  align-items: center;
  font-family: "Pretendard Variable";
}

.property--video__contents {
  width: 100%;
  flex: 1 0;
}

.property--video__contents-container {
  display: flex;
  gap: 6px;
  align-items: center;
}

.property--video__link {
  position: relative;
  cursor: pointer;
  display: flex;
  height: 32px;
  padding: 0px 8px;
  align-items: center;
  gap: 6px;

  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--black, #000);

  overflow: hidden;
  color: var(--whtie, #fff);
  text-overflow: ellipsis;

  /* body */
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.property--video__link:hover {
  border: 1px solid var(--w-40, rgba(255, 255, 255, 0.4));
  background: var(--black, #000);
}

.property--video__enter {
  visibility: hidden;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 3px;
  top: 3px;
  border-radius: 5px;
  background: var(--dropdown_dark, rgba(36, 38, 38, 0.8));
  backdrop-filter: blur(2px);
}

.property--video__link:hover .property--video__enter {
  visibility: visible;
}

.property--video__copy {
  position: relative;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.property__empty_title {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  height: 30px;
  padding: 0px 4px;
  align-items: center;
  border-radius: 5px;

  color: var(--placeholder, #7c7d7d);
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.property__empty_title:hover {
  border-radius: 5px;
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.property__empty_title > span {
  line-height: 22px;
}

.modal {
  position: absolute;
  top: 29px;
  right: 0;
  display: flex;
  width: 100px;
  height: fit-content;
  background: #363a3a;
  border: 1px solid #545959;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  z-index: 9999;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.modal > * {
  height: 30px;
  width: 100%;
  animation: fadeIn 350ms cubic-bezier(0, 0.6, 0, 1) forwards;
  font-family: "Pretendard Variable";
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  box-sizing: border-box;
  padding: 0px 10px;
}

.modal > *:hover {
  background: rgba(255, 255, 255, 0.08);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.selectedGuestRowDelete {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  transition: all 200ms cubic-bezier(0, 1, 0, 1);

  box-sizing: border-box;

  cursor: pointer;
  position: relative;
}

.selectedGuestRowDelete:hover {
  position: relative;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.selectedGuestRowDelete:hover .selectedGuestRowDeleteIcon {
  fill: #fff;
}

.selectedGuestRowDeleteIcon {
  /* transform: rotate(45deg); */
  margin-left: 1px;
  margin-bottom: 1px;
  fill: var(--w-24, rgba(255, 255, 255, 0.24));
}

.iconDescription {
  position: absolute;
  top: -23px;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s,
    opacity 0.3s linear;
  display: inline-flex;
  padding: 2px 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background: var(--line-btn, #545959);
  overflow: hidden;
  color: var(--g100, #d9d9d9);
  text-overflow: ellipsis;

  /* tooltip */
  font-family: "Pretendard Variable";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.11px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  width: max-content;
}

.property--video__copy:hover .iconDescription,
.selectedGuestRowDelete:hover .iconDescription {
  position: absolute;
  visibility: visible;
  opacity: 1;
  transition:
    visibility 0s,
    opacity 0.3s linear;
}
