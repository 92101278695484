.loading_component {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #545959;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #545959;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
