/* .account__contents__area {
  background: #363a3a;
  width: 100%;
  height: 100%;
} */

/* .account__contents {
  display: flex;
  flex-direction: column;
  background: #363a3a;
  padding-left: 40px;
  padding-top: 35px;
  padding-right: 40px;
} */

.account__contents .account__title {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.profile__total__area {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.profile__area {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
}

.profile__area img {
  width: 60px;
  height: 60px;
  border-radius: 100px;
}

.profile__text__area {
  display: flex;
  flex-direction: column;
  margin-left: 22px;
  align-self: center;
  flex-grow: 1;
}

.profile__text__area .profile__nickname {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
}

.profile__text__area .profile__email {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.profile__btns__area {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.profile__btns__area .logout__button {
  display: inline-block;
  line-height: 36px;
  width: 120px;
  height: 36px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.12px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: #363a3a;
  margin-left: 20px;
  cursor: pointer;
}

.delete__button {
  margin-top: 6px;
  color: #7c7d7d;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.12px;
  text-decoration-line: underline;
  align-self: end;
}

.believer__contents__area {
  display: flex;
  background: #363a3a;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 32px;
}

.believer__contents__area .believer__title {
  display: flex;
  width: 89px;
  height: 30px;
  margin-top: 30px;
  border-radius: 100px;
  background: #69e4ff;
  color: #363a3a;
  font-family: "Montserrat Variable";
  font-size: 16px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
}

.believer__guide__text__area {
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  margin-left: 20px;
}

.believer__guide__text {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: start;
}

.believer__invitation__area {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
}

.believer__invitation__area .invitation__title {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.believer__invitation__area .count__description {
  color: #d9d9d9;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.12px;
}

.invitation__list__area {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 10px;
}

.invitation__list__item__send {
  display: flex;
  width: 120px;
  height: 40px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid #69e4ff;
  gap: 10px;
  justify-content: center;
  cursor: pointer;
}

.invitation__list__item__progress {
  display: flex;
  width: 366px;
  height: 40px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid #69e4ff;
  gap: 5px;
  justify-content: center;
  background: #262929;
}

.invitaion__input {
  width: 280px;
  caret-color: #6bdcff;
  border: none;
  background: #262929;
  outline: none;
  color: #fff;
}

.invitation__list__item__completed {
  display: flex;
  width: fit-content;
  height: 40px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid #abadad;
  background-color: #363a3a;
  gap: 10px;
  justify-content: center;
  cursor: pointer;
}

.invitation__list__item__completed .text {
  color: #abadad;
  flex-grow: 1;
  margin-right: 16px;
}

.invitation__list__item__completed .dot {
  color: #abadad;
  margin-left: 16px;
}

.text {
  color: #69e4ff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
}

.dot {
  width: 10px;
  height: 10px;
}

.plus {
  width: 24px;
  height: 24px;
}

.billing__area {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  gap: 20px;
}

.billing__title {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: start;
}

.billing__description {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: start;
}

.billing__change__btn {
  display: flex;
  width: 180px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: #363a3a;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.toast {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  gap: 10px;
  padding: 20px;
  align-items: center;
  border: 1px solid #545959;
  border-radius: 6px;
}

.toast.show {
  visibility: visible; /* 보이도록 설정 */
  animation:
    fadein 0.5s,
    fadeout 0.5s 2.5s;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.toast .icon {
  width: 16px;
  height: 16px;
}

.toast .close {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.toast .message {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}
