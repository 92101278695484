.popup {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 400px;
  height: 310px;
  top: 50%;
  left: 50%;
  border-radius: 6px;
  border: 1px solid #545959;
  background: #363a3a;
  z-index: 100000;
  transform: translate(-50%, -50%);
  padding: 10px 0px 30px 0px;
  align-items: center;
}

.popup__close {
  position: fixed;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.close {
  width: 20px;
  height: 20px;
}

.popup__icon {
  margin-top: 55px;
  width: 160px;
  height: 68px;
}

.invitee__email {
  margin-top: 2px;
  color: #69e4ff;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 10px;
}

.title {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
}

.button {
  display: flex;
  width: 330px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #545959;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  margin-top: 18px;
}

.description {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 6px;
}
