.morePopup {
  position: fixed;
  min-width: 180px;
  z-index: 1000;
  padding: 6px 5px;
  box-sizing: border-box;
  background-color: rgba(36, 38, 38, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 6px;
  backdrop-filter: blur(4px);
  color: #fff;
  transform: translateX(-50%);
}

.dateWrapper {
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 4px 2px;
}
.date {
  font-family: "Montserrat Variable";
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
  border-radius: 6px;
}
.day {
  font-family: "Montserrat Variable";
  font-size: 10px;
  font-weight: 600;
}
.today {
  font-weight: 600;
  color: rgba(26, 28, 28, 1);
  background-color: rgba(105, 228, 255, 1);
}
.eventWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.notAllDayEventWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
.notAllDayEvent {
  flex-grow: 1;
}
.time {
  min-width: 42px;
  font-size: 10px;
  color: rgba(171, 173, 173, 1);
}
.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.16);
  margin: 5px 0;
}

.showMore {
  display: flex;
  align-items: center;
  padding: 0 8px;
  width: 100%;
  height: 24px;
  position: relative;
  border-radius: 6px;
  color: rgba(171, 173, 173, 1);
  font-family: "Pretendard";
  font-weight: 400;
  font-size: 12px;
}

.showMore:hover,
.showMore:focus {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(36, 38, 38, 1);
}
