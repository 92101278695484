.reconnect {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
}

.reconnect_textbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reconnect_title {
  color: var(--g100, #d9d9d9);
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.reconnect_description {
  color: var(--placeholder, #7c7d7d);

  /* body-s */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.12px;
}

.reconnect_btn {
  box-sizing: border-box;
  display: flex;
  width: 145px;
  height: 40px;
  padding: 10px 12px 9px 11px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));

  color: var(--g100, #d9d9d9);
  text-align: center;
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.reconnect_btn_text {
  color: var(--g100, #d9d9d9);
  text-align: center;

  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */

  white-space: nowrap;
}

.reconnect_btn:hover {
  background: var(--w-16, rgba(255, 255, 255, 0.16));
}
