/* gmail css */

.mailItem {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  padding: 12px 10px;
  height: 92px;
  min-height: 92px;

  border: 1px solid rgba(84, 89, 89, 1);
  user-select: none;
  cursor: pointer;
  animation: mailItemFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;

  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: #2f3131;
}

.dragging {
  width: 100%;
  padding: 12px 10px;
  height: 92px;
  min-height: 92px;
  opacity: 0;
}

.mailItem:hover {
  background: #373939;
}

@keyframes mailItemFadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
    filter: blur(2px);
  }
  to {
    opacity: 1;
    transform: scale(1);
    filter: blur(0px);
  }
}

.mailItem-empty {
  color: #363a3a;
  font-weight: 800;
  font-size: 16px;
  height: 30px;
}

.mailItem-type {
  width: 100%;
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.mailItem-type > span {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 220px;
}

.mailItem-type-icon {
  width: 12px;
  height: 12px;
  background-image: url(../../../../../assets/Integration/mail-sender.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: inherit;
}

.mailItem-title {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.mailItem-body {
  width: 100%;
  height: 34px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.4);
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}

/* jira,slack css */

.item-area {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  padding: 12px 10px;
  height: 92px;
  min-height: 92px;

  border: 1px solid rgba(84, 89, 89, 1);
  user-select: none;
  cursor: pointer;
  animation: mailItemFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;

  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: #2f3131;
}

.item-area:hover {
  background: #373939;
}

.name-create-area {
  display: inline-flex;
  gap: 5px;
  align-items: center;
}

.item-area .name {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;
  line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 21px;
  flex-grow: 1;
  flex-shrink: 1;
}

.item-area .time {
  color: var(--placeholder, #7c7d7d);
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  flex-shrink: 0;
  white-space: nowrap;
}

.item-area .description-container {
  width: 100%;
}

.item-area .description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  word-wrap: break-word;
  max-height: 34px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.item-area .channel {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  min-height: 17px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;
  line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}

.expand-jira-slack-head {
  box-sizing: border-box;
  width: 100%;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: white;
  padding: 27px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.jira-create-area {
  display: inline-flex;
  gap: 5px;
  align-items: center;
}

.item-area .project-name {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.item-area .issue-title {
  display: -webkit-box;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.item-area .description-container {
  width: 100%;
}

.item-area .description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  word-wrap: break-word;
  max-height: 34px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #7c7d7d;
}

.profile-icon {
  position: absolute;
  top: 12px;
  right: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.slack-id-highlight {
  color: #69e4ff;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
}

.slack-link-highlight {
  color: #69e4ff;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  text-decoration-line: underline;
}

.inlineElements {
  display: inline;
}

.slackTextType {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
}
