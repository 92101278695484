.rbc-calendar {
  background: rgba(26, 28, 28, 1);
  align-items: center;
  width: 100%;
}

.rbc-event-label {
  display: none;
}

.rbc-header {
  font-family: "Montserrat Variable";
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  border: none;
  padding: 0;
}

.rbc-header:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.08);
}

.rbc-header + .rbc-header {
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.08);
}

.rbc-header > * {
  margin-left: 10px;
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.rbc-day-bg {
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.rbc-allday-cell .rbc-row-content {
  height: 85px;
}

.rbc-row-content {
  font-family: "Montserrat Variable";
  font-size: 12px;
  font-weight: 600;
  overflow-y: scroll;
  overflow-x: hidden;
}

.rbc-row-content::-webkit-scrollbar {
  display: none;
}

.rbc-today {
  background-color: rgb(255, 255, 255, 0);
}

.rbc-date-cell {
  padding-left: 10px;
  padding-top: 10px;
  text-align: left;
  font-size: 12px;
  font-family: "Montserrat Variable";
  font-weight: 600;
}
.rbc-date-cell::-webkit-scrollbar {
  display: none;
}

.rbc-off-range-bg {
  background: none;
}

.rbc-time-view {
  border: none;
}

.rbc-time-content {
  border-top: 1px solid rgba(84, 89, 89, 1);
  overflow-y: scroll;
  overflow-x: hidden;
}
.rbc-time-content::-webkit-scrollbar {
  display: none;
}

.rbc-time-content::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0);
  border-radius: 10px;
  border: 5px solid rgba(26, 28, 28, 1);
}

.rbc-time-content:hover::-webkit-scrollbar-thumb {
  background: rgba(97, 97, 97, 1);
}

.rbc-time-content::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 15px;
}

.rbc-time-content::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

.weekend {
  background: rgba(255, 255, 255, 0.01);
}

.rbc-day-slot {
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.04);
  margin-bottom: 100px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.rbc-day-slot::after {
  pointer-events: none;
  content: "";
  display: block;
  box-sizing: content-box;
  border-right: 1px solid rgba(255, 255, 255, 0.04);
  width: 100%;
  height: 100px;
  position: absolute;
  right: -1px;
  bottom: -100px;
  z-index: 1000;
}
.rbc-day-slot.weekend::after {
  background: rgba(255, 255, 255, 0.01);
}

.rbc-day-slot .rbc-time-slot {
  border-top: none;
}

.rbc-timeslot-group {
  border: none;
}

.rbc-time-content > .rbc-day-slot > .rbc-timeslot-group {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.rbc-time-content > .rbc-day-slot > .rbc-timeslot-group:first-child {
  border-top: none;
}

.rbc-event:focus {
  outline: none;
}

.rbc-event.rbc-selected {
  background-color: #242626;
}

.rbc-time-header-content {
  border: none;
}

.rbc-time-view .rbc-time-header-cell {
  border-bottom: 1px solid rgba(84, 89, 89, 1);
  position: relative;
  min-height: 40px;
}
.rbc-time-view .rbc-time-header-cell::before {
  display: block;
  content: "";
  position: absolute;
  width: 60px;
  height: 1px;
  background-color: rgba(84, 89, 89, 1);
  bottom: -1px;
  left: -60px;
}

.rbc-time-view .rbc-allday-cell::-webkit-scrollbar {
  display: none;
}

.rbc-time-header.rbc-overflowing {
  border: none;
  margin-right: 0 !important;
}

.rbc-time-view .rbc-header {
  font-size: 12px;
  line-height: 36px;
  font-weight: 600;
  background: none;
}
.rbc-time-view .rbc-header .dayWeekHeader {
  display: flex;
  align-items: center;
  gap: 6px;
}
.rbc-time-view .rbc-header .dayWeekHeader .date {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.rbc-time-view .rbc-today .dayWeekHeader .date {
  border-radius: 6px;
  background-color: rgba(105, 228, 255, 1);
  color: #1a1c1c;
}

.rbc-time-view .rbc-today .dayWeekHeader .dayOfWeek {
  color: rgba(105, 228, 255, 1);
}

.rbc-current-time-indicator {
  background-color: rgba(105, 228, 255, 1);
}
.time-indicator {
  height: 1px;
  position: absolute;
  left: 60px;
  background-color: rgba(105, 228, 255, 0.3);
  z-index: 90;
}
.time-indicator span {
  position: absolute;
  left: -52px;
  top: 0;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  height: 16px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  color: rgba(105, 228, 255, 1);
  border-radius: 2px;
  backdrop-filter: blur(2px);
}

.rbc-time-slot .rbc-label {
  display: block;
  margin-top: -7px;
}

.rbc-time-header-gutter {
  width: 60px;
}

.rbc-label {
  color: #abadad;
  font-size: 11px;
  font-weight: 400;
  padding: 0;
}

.rbc-label .timeGutterHeader {
  margin-top: 40px;
  padding: 5px 8px 0;
  text-align: right;
}

.rbc-time-gutter {
  width: 60px;
  padding: 0 8px;
  position: relative;
}

.rbc-row-segment {
  height: 30px;
}

.rbc-show-more {
  width: calc(100% - 10px);
  border-radius: 6px;
  background-color: transparent;
}

.rbc-show-more:hover,
.rbc-show-more:focus {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(36, 38, 38, 1);
}

.rbc-overlay {
  border: 1px solid rgba(84, 89, 89, 1);
  border-radius: 6px;
  background-color: rgba(36, 38, 38, 1);
  padding: 5px;
  overflow-y: auto;
  opacity: 0;
  transform: translateY(-10px);
  animation: overlay-fadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  /* transform: translateY(0px); */
}
.rbc-overlay .rbc-event {
  border-radius: 6px;
}

@keyframes overlay-fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.rbc-overlay-header {
  display: none;
}

.rbc-overlay::-webkit-scrollbar-thumb {
  /* background: rgba(255, 255, 255, 0); */
  border-radius: 10px;
  border: 5px solid rgba(36, 38, 38, 1);
  background: rgba(97, 97, 97, 1);
}

.rbc-overlay::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 15px;
}

.rbc-overlay::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

.rbc-overlay .rbc-event-content {
  width: 100%;
}

.rbc-selected-cell {
  background: rgba(255, 255, 255, 0.04);
}

.rbc-slot-selection {
  background: rgba(255, 255, 255, 0.04);
}

.rbc-time-header-cell-single-day {
  display: block;
}

/* monthly view css */
.rbc-month-view {
  border: none;
}

.rbc-month-view .rbc-month-header {
  height: 25px;
  line-height: 25px;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.rbc-month-view .rbc-header {
  text-align: center;
  font-size: 10px;
  color: rgba(217, 217, 217, 1);
}

.rbc-month-view .rbc-date-cell {
  padding: 5px 6px;
  font-weight: 400;
  font-size: 14px;
}
.rbc-month-view .rbc-date-cell .rbc-button-link {
  padding: 5px;
}

.rbc-month-view .rbc-date-cell.rbc-now .rbc-button-link {
  background-color: rgba(105, 228, 255, 1);
  border-radius: 6px;
  color: rgba(26, 28, 28, 1);
  font-weight: 600;
}

.rbc-month-view .rbc-header:first-child {
  border-left: none;
}
.rbc-month-view .rbc-day-bg:first-child {
  border-left: none;
}

.rbc-month-view .rbc-event,
.allDay-event {
  width: calc(100% - 10px);
}

.rbc-time-content .rbc-addons-dnd-drag-preview.allDay-event {
  height: 16px !important;
}

.rbc-addons-dnd .rbc-event.meetWith-event {
  cursor: auto;
}
