.modal {
  position: fixed;
  display: flex;
  width: 190px;
  height: fit-content;
  background: rgb(54, 58, 58);
  border: 1px solid rgb(84, 89, 89);
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  z-index: 9999;
  text-align: left;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalChild {
  width: 100%;
  height: 30px;
  animation: fadeIn 350ms cubic-bezier(0, 0.6, 0, 1) forwards;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.modalChild:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 10px;
}

.circle {
  position: relative;
  width: 14px;
  height: 14px;
}

.innerCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.outerCircle {
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.selected .outerCircle {
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
}

.hovered .outerCircle {
  width: 10px;
  height: 10px;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.modal > hr {
  width: 100%;
  height: 1px;
  background: var(--hover, rgba(255, 255, 255, 0.08));
  border: none;
  margin: 0px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
