.timeBlockOnly {
  display: flex;
  position: relative;
  margin-top: 15px;
  box-sizing: border-box;
  align-items: flex-start;
  flex-direction: row;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-top: 17.5px;
}

.timeBlockBox {
  cursor: pointer;
  display: inline-flex;
  width: 360px;
  height: 40px;
  margin-left: 10px;
  justify-content: center;
  align-items: flex-start;
  color: #7c7d7d;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  box-sizing: border-box;

  border-radius: 6px;
  border: 1px solid var(--P1, #667eff);
  background: linear-gradient(0deg, rgba(102, 126, 255, 0.1) 0%, rgba(102, 126, 255, 0.1) 100%),
    #242626;
}

.timeBlockListArea {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 500px;
}

.dateTimeArea {
  display: inline-block;
  padding-right: 10px;
  height: 14px;
  width: 66px;
  color: #7c7d7d;
  font-size: 11px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.11px;
  text-align: right;
  box-sizing: border-box;
  margin-top: 13px;
}

.taskList__body {
  width: 360px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  padding-right: 10px;
  box-sizing: border-box;
  gap: 10px;
}

.taskList__body__integration {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: rgba(26, 28, 28, 1);
  color: white;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  position: absolute;
  left: 234px;
  left: 334px;
  opacity: 1;
}

.taskListBodyIcon {
  min-width: 16px;
  min-height: 16px;
  border-radius: 3px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.taskListBodyIcon:hover {
  background-image: url(../../assets/Common/timeblock-check-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.checkboxCompleted {
  background-image: url(../../assets/Common/timeblock-check-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.taskList__body__title {
  color: white;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 36px;
  line-height: 36px;
  border: none;
  background: none;
  flex-grow: 1;
}

.googleMeetIcon {
  width: 16px;
  height: 16px;
  margin: 10px 10px;
  background: url(../../assets/Wiki/googlemeet-icon.svg);
  background-size: inherit;
  background-repeat: no-repeat;
  background-position: center;
}

.guests {
  min-height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* position: relative; */
}
.guests:hover .guests-info {
  visibility: visible;
}

.guests-1 {
  width: 16px;
  background-image: url(../../assets/Main/guest_01.png);
}
.guests-2 {
  width: 27px;
  background-image: url(../../assets/Main/guest_02.png);
}
.guests-3 {
  width: 38px;
  background-image: url(../../assets/Main/guest_03.png);
}
.guests-many {
  width: 48px;
  background-image: url(../../assets/Main/guest_many.png);
}
.guests-many-month {
  width: 26px;
  background-image: url(../../assets/Main/guest_many_month.png);
}

.guests-info {
  height: fit-content;
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 6px 8px;
  gap: 2px;
  border-radius: 4px;
  z-index: 1000;
  top: 26px;
  visibility: hidden;
  user-select: none;
}

.guests-info-item {
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  color: black;
}

.integration {
  min-width: 16px;
  min-height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.integration-gmail {
  background-image: url(../../assets/Main/gmail-icon.svg);
}

.integration-jira {
  background-image: url(../../assets/Main/jira-icon.svg);
}

.integration-slack {
  background-image: url(../../assets/Main/slack-icon.svg);
}
