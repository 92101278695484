.App {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: fixed;
}

::selection {
  background: white;
  color: black;
}
