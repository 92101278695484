.setting-property-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  min-height: 30px;
  line-height: 30px;
  /* gap: 6px; */
}

.setting-property {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  font-size: 14px;
  font-weight: 400;
  flex-grow: 1;
  position: relative;
}

.selected_project {
  box-sizing: border-box;
  display: flex;
  height: 30px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;

  max-width: 650px;
  overflow: hidden;
  text-overflow: ellipsis;

  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.selected_project_mini {
  max-width: 316px;
}

.projectRow-body-icon {
  width: 10px;
  height: 10px;
  border-radius: 16px;
  box-shadow: 0 0 0 2px white inset;
  background: white;
  margin: 3px 3px;
  flex-shrink: 0;
}

.setting-property-label {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  flex-grow: 1;
  max-width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.4;
  white-space: nowrap;
  user-select: none;
}

.selectedGuestRowDelete {
  visibility: hidden;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  transition: all 200ms cubic-bezier(0, 1, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;

  position: absolute;
  right: 3px;
  border-radius: 5px;
  background: var(--dropdown_dark, rgba(36, 38, 38, 0.8));
  backdrop-filter: blur(2px);
}

.selected_project:hover .selectedGuestRowDelete {
  visibility: visible;
}

.selectedGuestRowDelete:hover {
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  backdrop-filter: blur(2px);
  background: var(--dropdown_dark, rgba(36, 38, 38, 0.8));
}

.selectedGuestRowDelete:hover .selectedGuestRowDeleteIcon {
  fill: #fff;
}

.selectedGuestRowDeleteIcon {
  margin-left: 1px;
  margin-bottom: 1px;
  fill: var(--w-24, rgba(255, 255, 255, 0.24));
}

.label-on {
  color: white;
  opacity: 1;
}

.property__empty_title {
  /* width: 100%; */
  flex: 1 0 0;
  /* box-sizing: border-box; */
  display: flex;
  height: 30px;
  padding: 0px 4px;
  align-items: center;
  border-radius: 5px;

  color: var(--placeholder, #7c7d7d);
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  position: relative;
}

.property__empty_title:hover {
  border-radius: 5px;
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.property__empty_title > span {
  line-height: 22px;
}

/* Modal */
.wrap {
  position: absolute;
  top: 38px;
  left: 0px;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  /* TODO UI 체크 필요 */
  max-height: 270px;
  padding: 4px 0px 4px 8px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  border-radius: 5px;
  background: var(--task-task_dropdown, #2f3131);
  box-sizing: border-box;

  overflow-y: scroll;
  overflow-x: hidden;

  font-size: 14px;
  font-weight: 400;
  flex-grow: 1;

  animation: modalFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.wrap::-webkit-scrollbar {
  width: 8px;
}

.showScrollbar.wrap::-webkit-scrollbar {
  width: 8px;
  display: block;
}

.wrap::-webkit-scrollbar-thumb {
  background: transparent;
  width: 2px;
  height: 100px;
  background-clip: padding-box;
  border: 3px solid transparent;
  transition: background 0.5s ease;
  opacity: 0;
}

.showScrollbar.wrap::-webkit-scrollbar-thumb {
  background: var(--w-16, rgba(255, 255, 255, 0.16));
  width: 2px;
  height: 100px;
  background-clip: padding-box;
  border: 3px solid transparent;
  opacity: 1;
}

.wrap::-webkit-scrollbar-thumb:hover {
  background: rgba(84, 89, 89, 1);
  border-radius: 99px;
  background-clip: padding-box;
  border: 2px solid transparent;
  width: 4px;
  height: 100px;
}

.wrap::-webkit-scrollbar-track {
  background: transparent;
}

/* ProjectRow */
.projectRow-add,
.projectRow-body {
  position: relative;
  display: flex;
  flex: 1 0 0;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  cursor: pointer;

  height: 36px;
  min-height: 36px;
  padding: 0px 6px;

  gap: 6px;
}

.projectRow-add:hover,
.projectRow-body:hover {
  border-radius: 5px;
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  z-index: -1;
  animation: hoverFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

.projectRow-body.no-hover:hover::before {
  display: none;
}

@keyframes hoverFadeIn {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

.projectRow-add {
  gap: 7px;
}

.projectRow-add:hover {
  color: white;
}

.projectRow-body-icon {
  width: 10px;
  height: 10px;
  border-radius: 16px;
  box-shadow: 0 0 0 2px white inset;
  background: white;
  margin: 3px 3px;
}

.projectRow-body-title {
  color: white;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  line-height: 16px;
  border: none;
  background: none;
  cursor: pointer;
}

.projectRow-add-icon {
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  color: #abadad;
}

.projectRow-add-title {
  font-weight: 400;
  font-size: 14px;
  color: white;
  opacity: 0.4;
}
