.property__title {
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  transition: width 0.3s ease; /* 너비 변화에 애니메이션 적용 */
  padding: 0px 2px;
}

.property__title--collapsed {
  width: 24px;
}

.property__title--expanded {
  width: 120px;
}
.property__icon {
  /* height: 30px; */
}

.property__label {
  display: flex;
  align-items: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--project-default-grey, #abadad);
  text-overflow: ellipsis;
}

.property__label > span {
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  vertical-align: middle;
}

.selected_text {
  color: white;
}
