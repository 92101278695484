.modal {
  position: fixed;
  display: flex;
  width: 100px;
  height: fit-content;
  background: #363a3a;
  border: 1px solid #545959;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  z-index: 9999;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.modal > * {
  height: 30px;
  width: 100%;
  animation: fadeIn 350ms cubic-bezier(0, 0.6, 0, 1) forwards;
  font-family: "Pretendard Variable";
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  box-sizing: border-box;
  padding: 0px 10px;
}

.modal > *:hover {
  background: rgba(255, 255, 255, 0.08);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
