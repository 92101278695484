/* IntegrationItemList */

.rowIcon {
  position: relative;
  box-sizing: border-box;
  display: flex;
  width: 28px;
  height: 28px;
  padding: 6.222px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #000;
  cursor: pointer;
  background-color: #000;
}

.rowIcon:hover {
  border-radius: 5px;
  border: 1px solid var(--line-btn, #545959);
  background: #000;
}

.rowIcon:hover .messageBox {
  visibility: visible;
  opacity: 1;
}

.rowIcon-selected {
  border: 1px solid var(--project-default-grey, #abadad);
}

.rowIcongmail {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  background-image: url(../../assets/Integration/gmail-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.rowIconslack {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  background-image: url(../../assets/Integration/slack-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.rowIconjira {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  background-image: url(../../assets/Integration/jira-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.rowIconNotion {
  background: rgb(255, 122, 217);
}

.rowIconFigma {
  background: rgb(122, 255, 157);
}

.messageBox {
  position: absolute;
  bottom: 5px;
  visibility: hidden;
  opacity: 0;
  background-color: #545959;
  color: white;
  text-align: left;
  border-radius: 3px;
  padding: 2px 5px;
  transition:
    visibility 0.2s,
    opacity 0.2s ease-in-out;
  white-space: nowrap;
  transform: translateY(150%);
  z-index: 100;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.11px;
}

/* Integration  */
.integrationOverlay {
  position: absolute;
  z-index: 1000;
}

.integration {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1001;
  background-color: #242626;
  border-radius: 8px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--task-500-default, #242626);
}

.integrationContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 10px;
}

.title {
  color: #fff;
  font-family: "Montserrat Variable";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.close__button {
  cursor: pointer;
  padding: 4px;
  justify-content: center;
  align-items: center;
}

.close__button:hover {
  border-radius: 5px;
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.loading_component {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #545959;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #545959;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.connected__list {
  display: flex;
  width: 280px;
  height: max-content;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  box-sizing: border-box;
  padding: 12px 10px;
  border-bottom: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.flexibleContent {
  display: flex;
  flex-direction: column;
  height: calc(100% - 107px);
}

.add-icon {
  box-sizing: border-box;
  color: rgba(171, 173, 173, 0.4);
  cursor: pointer;
  transition: all 300ms cubic-bezier(0, 1, 0, 1);

  display: flex;
  width: 28px;
  height: 28px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.add-icon:hover {
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.add-icon-big {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: rgba(171, 173, 173, 0.4);
  cursor: pointer;
  transition: all 300ms cubic-bezier(0, 1, 0, 1);

  display: flex;
  width: 40px;
  height: 40px;

  justify-content: center;
  align-items: center;

  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.add-icon-big:hover {
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.noIntegrations {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  flex: 1;
  align-self: stretch;
}

.noIntegrations > span {
  color: var(--placeholder, #7c7d7d);

  /* body */
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

/* 
.body::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0);
  border-radius: 10px;
  border: 7px solid #363a3a;
}

.body:hover::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}

.body-default:hover::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0);
} */

.projectGroup::-webkit-scrollbar-button:vertical:start:decrement,
.projectGroup::-webkit-scrollbar-button:vertical:start:increment {
  display: block;
  height: 36px;
}

.body::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 0px;
}

.body::-webkit-scrollbar-track {
  background: #363a3a;
}

.account_info {
  display: flex;
  height: 40px;
  padding: 0px 10px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
}

.account_info span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: var(--project-default-grey, #abadad);
  text-overflow: ellipsis;

  /* body-s */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.12px;
}

.refresh__button {
  box-sizing: border-box;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
}
.select_box_container {
  position: relative;
  display: flex;
  padding: 0px 10px 10px 10px;
  align-items: center;
  align-self: stretch;
}

.select_box {
  display: flex;
  width: 100%;
  height: 36px;
  padding: 0px 8px;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid var(--w-16, rgba(255, 255, 255, 0.16));
  cursor: pointer;
  user-select: none;
}

.select_title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;

  overflow: hidden;
  color: var(--g100, #d9d9d9);
  text-overflow: ellipsis;

  /* body */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.select_button {
  width: 16px;
  height: 16px;
}

.expand-head {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: white;
  /* margin: 27px 20px; */
  display: flex;
  align-items: center;

  display: flex;
  height: 36px;
  padding: 0px 8px;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid var(--w-16, rgba(255, 255, 255, 0.16));
}

.expand-head-refresh:hover {
  transform: rotate(360deg);
}

.expand_head_refresh {
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url(../../assets/Integration/refresh.svg);
  background-size: inherit;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 1000ms cubic-bezier(0, 0.82, 0.165, 1);
}

.mail__dropdown {
  display: flex;
  height: 36px;
  padding: 0px 8px;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;

  border-radius: 6px;
  border: 1px solid var(--w-16, rgba(255, 255, 255, 0.16));
}

.mail__dropdown__title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
}

.dropdown-container {
  position: absolute;
  top: 0; /* 선택 박스 바로 아래 */
  left: 100%; /* 선택 박스의 오른쪽에 위치 */
  margin-left: 10px; /* 선택 박스와의 간격 조정 */

  display: flex;
  width: 220px;
  height: max-content;
  padding: 5px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex-wrap: nowrap;
  justify-content: center;

  font-size: 14px;
  font-weight: 400;
  /* line-height: 21px; */
  text-align: left;
  border-radius: 6px;

  border: 1px solid var(--line-btn, #545959);
  background: var(--dropdown_dark, rgba(36, 38, 38, 0.9));

  animation: expandDropboxFadeIn 300ms cubic-bezier(0, 0.7, 0, 1) forwards;
  z-index: 10;
  box-sizing: border-box;
}

.dropdown-body {
  width: 100%;
  height: 100%;
  list-style-type: none;

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  list-style: none;
  padding: 8px 0;
  margin: 0;
  z-index: 1000;
}

.dropdown-body > li {
  box-sizing: border-box;
  width: 200px;
  user-select: none;
  --user-drag: none;
  -webkit-user-drag: none;
  cursor: pointer;
  position: relative;

  display: flex;
  height: 30px;
  padding: 4px 9px 5px 0px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: 6px;
}

.dropdown-body > li:hover::before {
  content: "";
  width: 200px;
  height: 100%;
  background: #363a3a;
  border-radius: 6px;
  position: absolute;
  z-index: -1;
}

.dropdown-body > hr {
  width: 200px;
  height: 1px;
  border: none;
  background: #545959;
}

@keyframes expandDropboxFadeIn {
  from {
    left: 85%;
    /* opacity: 0; */
  }
  to {
    left: 100%;
    /* opacity: 1; */
  }
}

.labelArea {
  max-width: 198px;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 29px;
}

.selectIcon {
  width: 16px;
  height: 16px;
  background-image: url(../../assets/Integration/select-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: inherit;
}

.nonSelectIcon {
  width: 16px;
  height: 16px;
}

.labelName {
  max-width: 170px;
  color: #fff;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}

.account {
  width: 36px;
  height: 36px;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1) inset;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  background-image: url(../../assets/Main/account.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: inherit;
}

.account:hover {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.6) inset;
}

.body {
  width: 100%;
  display: flex;
  height: max-content;
  /* flex-direction: column; */
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  /* flex-grow: 1; */
}

.expand {
  width: 300px;
  height: 482px;
  /* height: 100dvh; */
  display: flex;
  position: absolute;
  top: 0px;
  right: -240px;
  background: rgba(36, 38, 38, 1);
  z-index: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  z-index: -1;
  transition: all 300ms cubic-bezier(0, 0.7, 0, 1);
}

.expand-on {
  right: 60px;
}

.expand-body {
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-left: 20px;
}

.expand-acc-add {
  width: 270px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(54, 58, 58, 1);
  color: white;
  text-align: center;
  line-height: 40px;
  font-weight: 400;
  font-size: 16px;
  margin-top: 36px;
  margin-left: 15px;
  cursor: pointer;
}

.expand-head-dropdown {
  width: 220px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.dropdown-head {
  width: 220px;
  height: 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  --user-drag: none;
  -webkit-user-drag: none;
  cursor: pointer;
  overflow: hidden;
}

.headOn {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.3);
}

.dropdown-head:hover {
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.07);
}

.dropdown-head .icon {
  margin-left: 5px;
  width: 16px;
  height: 16px;
  background: url(../../assets/Integration/dropdown-arrow-down.svg);
  background-size: initial;
  background-repeat: no-repeat;
  background-position: center;
}

.dropdownTitle {
  width: 184px;
  margin-left: 10px;
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;
  line-clamp: 1;
}

.dropdown-head .icon-on {
  transform: rotate(180deg);
}
.expand-mailList {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 280px;
  gap: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  padding-left: 10px;
}

.expand-mailList::-webkit-scrollbar {
  width: 10px; /* 스크롤바의 너비를 설정 */
}

.expand-mailList::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0); /* 스크롤바의 초기 배경 색상 */
  border-radius: 10px;
  border: 7px solid rgba(36, 38, 38, 1); /* 스크롤바의 테두리 색상 */
}

.expand-mailList:hover::-webkit-scrollbar-thumb {
  background: rgba(84, 89, 89, 1); /* 스크롤바에 마우스를 올렸을 때의 배경 색상 */
}

.expand-mailList::-webkit-scrollbar-track {
  background: rgba(36, 38, 38, 1); /* 스크롤바 트랙의 배경 색상 */
}

.resize__snapGap {
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
