.rbc-timeslot-group {
  min-height: 80px;
}

.rbc-row-segment {
  /* height:40px; */
  height: fit-content;
}

.rbc-day-slot .rbc-event,
.rbc-event {
  border: none;
  background: transparent;
}

.rbc-event {
  height: 19px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 12px;
  width: calc(100% - 10px);
}
.rbc-event.rbc-selected {
  background-color: transparent;
}

.rbc-day-slot .rbc-event {
  min-height: 18px;
  font-size: 14px;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  overflow: unset;
}

.rbc-addons-dnd .rbc-addons-dnd-resizable {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.rbc-row-segment .rbc-event-content {
  width: 100%;
  overflow: unset;
}

/* .rbc-day-slot .rbc-event{
    overflow: inherit;
} */

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor {
  width: 33.3%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:last-child {
  bottom: 1px;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor {
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
}

.event-tooltip {
  opacity: 0;
  box-sizing: border-box;
  height: 0;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  z-index: 1000;
  backdrop-filter: blur(4px);
  transition: opacity 0.3s;
  color: rgba(171, 173, 173, 1);
  font-family: "Pretendard Variable";
  font-size: 12px;
  font-weight: 500;
}

.rbc-addons-dnd-drag-preview .event-tooltip {
  display: none;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  padding: 0;
}
