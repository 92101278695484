.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.account__info {
  box-sizing: border-box;
  display: flex;
  width: 280px;
  height: 40px;
  padding: 0px 10px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
}

.integrationContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.account__info span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: var(--project-default-grey, #abadad);
  text-overflow: ellipsis;

  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.12px;
}

.refresh__button {
  box-sizing: border-box;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.refresh__button:hover {
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.refresh__button.disabled,
.refresh__button:disabled {
  cursor: not-allowed;
}

.refresh__button.disabled:hover,
.refresh__button:disabled:hover {
  background: none; /* hover 효과 없애기 */
}

.refresh__button.disabled,
.refresh__button:disabled .refresh__icon {
  cursor: not-allowed;
  fill: var(--badge-disabled, #484d4d);
}

.refresh__icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  fill: #abadad;
  /* background-image: url(../../assets/Integration/refresh.svg);
  background-size: inherit;
  background-position: center;
  background-repeat: no-repeat; */
  transition: all 1000ms cubic-bezier(0, 0.82, 0.165, 1);
}

.mail__options {
  position: relative;
  display: flex;
  padding: 0px 10px 10px 10px;
  align-items: center;
  align-self: stretch;
}

.mail__options__button {
  display: flex;
  width: 100%;
  height: 36px;
  padding: 0px 8px;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid var(--w-16, rgba(255, 255, 255, 0.16));
  cursor: pointer;
  user-select: none;
}

.mail__options__button:hover {
  border: 1px solid var(--w-16, rgba(255, 255, 255, 0.16));
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.mail__options__button_selected {
  border: 1px solid var(--w-16, rgba(255, 255, 255, 0.16));
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.mail__option_selected {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;

  overflow: hidden;
  color: var(--g100, #d9d9d9);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.sidebar__open_icon {
  width: 16px;
  height: 16px;
}

.mail__options__sidebar_container {
  position: absolute;
  top: 0; /* 선택 박스 바로 아래 */
  left: 100%; /* 선택 박스의 오른쪽에 위치 */
  margin-left: 10px; /* 선택 박스와의 간격 조정 */

  display: flex;
  width: 220px;
  height: max-content;
  padding: 5px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex-wrap: nowrap;
  justify-content: center;

  font-size: 14px;
  font-weight: 400;
  text-align: left;
  border-radius: 6px;

  border: 1px solid var(--line-btn, #545959);
  background: var(--dropdown_dark, rgba(36, 38, 38, 0.9));

  animation: expandDropboxFadeIn 300ms cubic-bezier(0, 0.7, 0, 1) forwards;
  z-index: 10;
  box-sizing: border-box;
}

.mail__options__list {
  width: 100%;
  height: 100%;
  list-style-type: none;

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  list-style: none;
  padding: 8px 0;
  margin: 0;
  z-index: 1000;
}

.mail__options__list > li {
  box-sizing: border-box;
  width: 200px;
  user-select: none;
  --user-drag: none;
  -webkit-user-drag: none;
  cursor: pointer;
  position: relative;

  display: flex;
  height: 30px;
  padding: 4px 9px 5px 0px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: 6px;
}

.mail__options__list > li:hover::before {
  content: "";
  width: 200px;
  height: 100%;
  background: #363a3a;
  border-radius: 6px;
  position: absolute;
  z-index: -1;
}

.mail__options__list > hr {
  width: 200px;
  height: 1px;
  border: none;
  background: #545959;
}

@keyframes expandDropboxFadeIn {
  from {
    left: 85%;
    /* opacity: 0; */
  }
  to {
    left: 100%;
    /* opacity: 1; */
  }
}

.labelArea {
  max-width: 198px;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 29px;
}

.selectIcon {
  width: 16px;
  height: 16px;
  background-image: url(../../../../assets/Integration/select-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: inherit;
}

.nonSelectIcon {
  width: 16px;
  height: 16px;
}

.labelName {
  max-width: 170px;
  color: #fff;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}

.expand_mailList_container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}

.expand_mailList {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 8px;

  /* overflow-y: scroll;
  overflow-x: hidden; */
  position: relative;
  padding: 0px 2px 0px 10px;
}

.expand_mailList_container::-webkit-scrollbar {
  width: 8px;
}

.expand_mailList_container::-webkit-scrollbar-thumb {
  background: var(--w-16, rgba(255, 255, 255, 0.16));
  width: 2px;
  height: 100px;
  background-clip: padding-box;
  border: 3px solid transparent;
}

.expand_mailList_container::-webkit-scrollbar-thumb:hover {
  background: rgba(84, 89, 89, 1);
  border-radius: 99px;
  background-clip: padding-box;
  border: 2px solid transparent;
  width: 4px;
  height: 100px;
}

.expand_mailList_container::-webkit-scrollbar-track {
  background: transparent;
}

.mailItem_empty {
  color: #363a3a;
  font-weight: 800;
  font-size: 16px;
  height: 30px;
}
