.header {
  width: 100%;
  padding-top: 9px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 15px;
  justify-content: flex-end;
  padding-right: 30px;
  container: header / inline-size;
  z-index: 100;
}

.header-left,
.header-right {
  display: flex;
  height: max-content;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 15px;
  position: relative;
}

.month-ctr {
  height: 32px;
  background: rgba(255, 255, 255, 0);
  display: flex;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 38px;
  user-select: none;
  padding: 0 4px;
}

.prev {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url(../../assets/Main/arrow-left.svg);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  opacity: 0.7;
  transition: opacity 450ms cubic-bezier(0, 1, 0, 1);
}

.next {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url(../../assets/Main/arrow-right.svg);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transition: opacity 450ms cubic-bezier(0, 1, 0, 1);
  opacity: 0.7;
}

.prev:hover,
.next:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.08);
}

.month-ctr span {
  width: 70px;
  color: rgba(171, 173, 173, 1);
  text-align: center;
}

.month-ctr div {
  cursor: pointer;
}

.today {
  width: 49px;
  height: 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  user-select: none;
  border-radius: 5px;
  background: rgba(54, 58, 58, 1);
  color: rgba(217, 217, 217, 1);
  text-align: center;
  cursor: pointer;
}

.today:hover {
  background: rgba(64, 68, 68, 1);
}

.view-ctr {
  width: fit-content;
  padding: 0px 8px;
  height: 32px;
  gap: 15px;
  display: flex;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 38px;
  user-select: none;
  color: rgba(171, 173, 173, 1);
  cursor: pointer;
}
.view-ctr:hover {
  background: rgba(255, 255, 255, 0.08);
}

.done {
  width: 70px;
  height: 38px;
  font-size: 12px;
  font-weight: 400;
  line-height: 38px;
  user-select: none;
  border-radius: 23px;
  border: 1px solid rgba(84, 89, 89, 1);
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  line-height: 38px;
  cursor: pointer;
}

.done:hover {
  border-color: rgba(255, 255, 255, 1);
  color: rgba(255, 255, 255, 1);
}
.viewTypeListWrapper {
  width: 140px;
  border-radius: 10px;
  background-color: rgba(36, 38, 38, 1);
  border: 1px solid rgba(255, 255, 255, 0.08);
  position: absolute;
  top: 39px;
  right: 0;
  z-index: 1000;
  overflow: hidden;
}

.viewTypeList li {
  height: 30px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}
.viewTypeList li:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.viewTypeList li .checkedIcon {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.viewTypeList li .checkedIcon.checked {
  background-image: url(../../assets/Main/check-white.svg);
}

.meetWithContainer {
  position: relative;
}
.meetWithButton {
  height: 32px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  font-size: 14px;
  color: rgba(171, 173, 173, 1);
  cursor: pointer;
}
.meetWithButton:hover,
.meetWithActive {
  background-color: rgba(255, 255, 255, 0.08);
}
.meetWithButton .meetWithBadgeWrapper {
  display: flex;
  gap: 4px;
  color: rgba(217, 217, 217, 1);
}
.meetWithButton .meetWithBadgeWrapper .meetWithBadge {
  display: flex;
}
.meetWithButton .meetWithBadge span {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  margin-left: -5px;
}
.meetWithButton .meetWithBadge span:first-of-type {
  margin-left: 0;
}
.meetWithButton .meetWithBadge .badgeMore {
  background-color: rgba(72, 77, 77, 1);
  background-image: url("../../assets/Header/more.svg");
  background-size: cover;
}
