.meetWithListWrapper {
  position: absolute;
  top: 37px;
  right: 0;
  width: 300px;

  background-color: rgba(36, 38, 38, 1);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);
}
.meetWithListWrapper .searchInputWrapper {
  padding: 8px;
}
.meetWithListWrapper input {
  width: 100%;
  height: 36px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  border: none;
  outline: none;
  padding: 6px 10px;
  color: rgba(255, 255, 255, 1);
}
.meetWithListWrapper input::placeholder {
  color: rgba(124, 125, 125, 1);
}
.meetWithListWrapper .accountList {
  max-height: 368px;
  overflow: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  padding: 8px;
}
.meetWithListWrapper .accountList li {
  height: 36px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  padding-right: 24px;
  position: relative;
}
.meetWithListWrapper .accountList .accountItem .accountImg {
  margin: 2px;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.meetWithListWrapper .accountList .accountItem .accountImg img,
.meetWithListWrapper .accountList .accountItem .accountImg svg {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  box-sizing: border-box;
}
.meetWithListWrapper .accountList li:hover,
.meetWithListWrapper .accountList li.focusList {
  background-color: rgba(255, 255, 255, 0.08);
}
.meetWithListWrapper .accountList .accountItem:hover .accountImg,
.meetWithListWrapper .accountList li.focusList .accountImg {
  background-color: rgba(36, 38, 38, 1);
}
.meetWithListWrapper .accountList .accountItem .accountName {
  font-size: 14px;
  line-height: 1.5;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.meetWithListWrapper .accountList .accountItem .accountEmail {
  font-size: 12px;
  line-height: 1.5;
  color: rgba(124, 125, 125, 1);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.meetWithListWrapper .accountList li.notFound {
  font-size: 14px;
  color: rgba(124, 125, 125, 1);
  justify-content: center;
  cursor: auto;
}

.meetWithListWrapper .meetWithAccount {
  overflow: visible;
}
.meetWithListWrapper .meetWithAccount li {
  position: relative;
}
.meetWithListWrapper .meetWithAccount .accountItem .accountImg {
  margin: 0;
  width: 22px;
  height: 22px;
  border: 2px solid rgba(171, 173, 173, 1);
  border-radius: 50%;
}
.meetWithListWrapper .meetWithAccount .accountItem .meetWithBadge {
  display: block;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  position: absolute;
  right: 8px;
}
.meetWithListWrapper .meetWithAccount .notAccess {
  display: none;
}
.meetWithListWrapper .meetWithAccount li:hover .notAccess {
  position: absolute;
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  background-color: #545959;
  border-radius: 3px;
  color: #d9d9d9;
  font-size: 11px;
  font-weight: 500;
  padding: 0 5px;
  white-space: nowrap;
}
