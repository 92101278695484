.toastContainer {
  position: fixed;
  display: inline-flex;
  padding: 20px;
  align-items: center;
  height: 10px;
  gap: 10px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  align-items: center;
  z-index: 1000;
  border-radius: 6px;
  border: 1px solid #545959;
  background: #363a3a;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  will-change: transform, opacity;
  backface-visibility: hidden;
  perspective: 1000;
}

@keyframes moveUp {
  0% {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 0;
  }
}

.show {
  animation: moveUp 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.hide {
  animation: fadeOut 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.toastMessage {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.toastErrorIcon {
  width: 16px;
  height: 16px;
  background: url(../../assets/TaskDetail/validation-error.svg);
  contain: strict;
}

.toastSuccessIcon {
  width: 16px;
  height: 16px;
  background: url(../../assets/TaskDetail/validation-success.svg);
  contain: strict;
}

.toastDeleteIcon {
  width: 16px;
  height: 16px;
  background: url(../../assets/TaskDetail/validation-delete.svg);
  contain: strict;
}

.toastDoneIcon {
  width: 16px;
  height: 16px;
  background: url(../../assets/TaskDetail/validation-done.svg);
  contain: strict;
}

.toastGmailIcon {
  width: 16px;
  height: 16px;
  background: url(../../assets/Integration/gmail-icon.svg);
  contain: strict;
}

.toastSlackIcon {
  width: 16px;
  height: 16px;
  background: url(../../assets/Integration/slack-icon.svg);
  contain: strict;
}

.toastJiraIcon {
  width: 16px;
  height: 16px;
  contain: strict;
}

.toastCloseButton {
  width: 18px;
  height: 18px;
  background: url(../../assets/TaskDetail/circle-close.svg);
  cursor: pointer;
  contain: strict;
}
