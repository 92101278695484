.modalContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  border-radius: 8px;
  border: 1px solid var(--line-btn, #545959);
  background: var(--project-guest-popup, #363a3a);
  box-sizing: border-box;
}

.modalHeader {
  display: flex;
  width: 100%;
  height: 39px;
  align-items: center;
  padding-left: 15px;
  padding-right: 10px;
  gap: 10px;
}

.buttonArea {
  display: flex;
  width: 350px;
  padding: 10px;
  gap: 10px;
  justify-content: space-between;
  gap: auto;
  box-sizing: border-box;
}

.buttonLeftArea {
  display: flex;
  width: 130px;
  box-sizing: border-box;
}

.buttonRightArea {
  display: flex;
  width: 210px;
  align-items: space-between;
  gap: 10px;
  box-sizing: border-box;
}

.repeatMessageConatiner {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 15px;
  align-items: flex-start;
  gap: 5px;
}

input[type="radio"] {
  display: none;
}

.radioContainer {
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
  gap: 10px;
  height: 30px;

  color: #fff;

  /* body */
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */

  outline: none;
}

.unselected-radioIcon {
  width: 16px;
  height: 16px;
}

.radioContainer:hover .unselected-radioIcon {
  content: url("../../../assets/Popup/hovered-radio-btn.svg");
}

.radioContainer:focus .unselected-radioIcon {
  content: url("../../../assets/Popup/hovered-radio-btn.svg");
}

.modalDiscardButton {
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 6px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--project-guest-popup, #363a3a);
  color: var(--project-default-grey, #abadad);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;

  &:hover {
    background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  }
}

.modalCloseButton {
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 6px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--project-guest-popup, #363a3a);
  color: var(--whtie, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  }
}

.modalOkButton {
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 6px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-16, rgba(255, 255, 255, 0.16));
  color: var(--whtie, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    background: var(--w-24, rgba(255, 255, 255, 0.24));
  }
}

.headerTitle {
  overflow: hidden;
  color: var(--moba, #69e4ff);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;
  line-clamp: 1;
  flex: 1;
}

.deleteHeaderTitle {
  color: #ff7a7a;
}

.headerClose {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;

  transition: all 200ms cubic-bezier(0, 1, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;

  border-radius: 5px;

  &:hover {
    border-radius: 5px;
    background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  }
}

.headerClose:hover .selectedGuestRowDeleteIcon {
  fill: #fff;
}

.selectedGuestRowDeleteIcon {
  width: 16px;
  height: 16px;
  fill: var(--project-default-grey, #abadad);
}

.body-part {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
}

.body-row {
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.radio-input {
  opacity: 0;
  position: absolute;
}

.radio-control {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 7px;
  vertical-align: middle;
  background-color: inherit;
  color: rgba(105, 228, 255, 1);
  border: 2px solid rgba(171, 173, 173, 1);
  border-radius: 24px;
}

.radio-input:checked + .radio-control:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background-color: rgba(105, 228, 255, 1);
  border-radius: 12px;
  animation: radioFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes radioFadeIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.radio-input:checked + .radio-control {
  border-color: rgba(105, 228, 255, 1);
}

.radio-control {
  transform: scale(0.75);
}

.radio-label {
  font-size: 14px;
  font-weight: 400;
  color: white;
}
