.dateInputWrapper {
  position: relative;
}

.setting-property-btn {
  width: 100%;
  /* gap: 6px; */
  display: flex;
  align-items: flex-start;
}

.date_property {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  user-select: none;
  flex: 1 0 0;
}

.date_property--expand {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 0;
}

.property__empty_title {
  flex: 1 0 0;
  line-height: 30px;
  box-sizing: border-box;
  display: flex;
  height: 30px;
  padding: 0px 4px;
  align-items: center;
  border-radius: 5px;

  color: var(--placeholder, #7c7d7d);
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.property__empty_title:hover {
  border-radius: 5px;
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

/* start - end */
.date__total-datetime {
  display: flex;
  align-items: center;
  gap: 6px;
}

.date__start-datetime,
.date__end-datetime {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.date__datetime--expand {
  flex-direction: row;
}

.date__start-time,
.date__start-date,
.date__end-time,
.date__end-date {
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
}

.setting-property-label {
  width: 100%;
  line-height: 30px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.4;
  word-break: break-all;
  overflow: hidden;
  padding: 0px 4px;
  align-items: center;
  cursor: pointer;
}

.setting-property-label:hover {
  border-radius: 5px;
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.label-on {
  color: white;
  opacity: 1;
  height: 30px;
}

.date_partition {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
}

.time_sub_btns {
  display: flex;
  height: 30px;
  align-items: center;
  gap: 8px;
}

.date-toggle {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 9px;
}

.date-part {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.date-part-title {
  color: white;
  font-size: 12px;
  font-weight: 400;
  margin: 10px 0;
}

.date-switch {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 8px;
}

.date-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.date-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.date-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: -4px;
  bottom: -4px;
  background-color: white;
  transition: 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.date-input:checked + .date-slider {
  background: rgba(105, 228, 255, 0.2);
}

.date-input:focus + .date-slider {
  box-shadow: 0 0 1px rgba(105, 228, 255, 0.2);
}

.date-input:checked + .date-slider:before {
  transform: translateX(16px);
  background: rgba(105, 228, 255, 1);
}

.date-slider {
  border-radius: 34px;
}

.date-slider:before {
  border-radius: 50%;
}

.date-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.date-dropdown-date {
  flex: 1 1;
  height: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.dropdown-date-title {
  text-align: center;
  border: 1px solid rgba(84, 89, 89, 1);
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: white;
  line-height: 28px;
}

.datepicker-wrap {
  position: absolute;
  top: 36px;
  z-index: 100;
  animation: dropFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes dropFadeIn {
  from {
    opacity: 0;
    transform: translateY(-14px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.date-dropdown-time {
  width: 120px;
  height: 30px;
  border: 1px solid rgba(84, 89, 89, 1);
  color: white;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  line-height: 30px;
  position: relative;
}

.date-interval {
  display: flex;
  height: 24px;
  padding: 0px 6px;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--g100, #d9d9d9);
  text-overflow: ellipsis;

  /* body-s */
  font-family: "Pretendard Variable";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 16.8px */
  letter-spacing: -0.12px;

  -moz-line-clamp: 1;
  -ms-line-clamp: 1;
  line-clamp: 1;
  flex-shrink: 0;
  overflow: hidden;
}

.validationErrorIcon {
  margin-top: 7px;
  min-width: 16px;
  min-height: 16px;
  background: url(../../../assets/TaskDetail/validation-error.svg);
}

.wrap {
  overflow: visible;
  position: absolute;
  display: flex;
  top: 36px;
  width: 100%;
  left: 0px;
  background: rgba(54, 58, 58, 1);
  border: 1px solid rgba(84, 89, 89, 1);
  border-radius: 0px 0px 6px 6px;
  box-sizing: border-box;
  z-index: 1000;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  animation: modalFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

/*  아래 버튼까지 */

/* 요소의 너비가 670px ~ 780px일 때 flex-direction을 row로 설정 */
@media (min-width: 670px) {
  .date_property--expand {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    flex: 1 0 0;
  }
}

/* @media (min-width: 460px) and (max-width: 549px) {
  .date_property {
    flex-direction: column;
  }
} */

/* Container for the toggle */
.toggle_container {
  display: flex;
  height: 24px;
  padding: 0px 6px;
  align-items: center;
  gap: 6px;

  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  cursor: pointer;
}

.toggle_container--selected {
  display: flex;
  height: 24px;
  padding: 0px 6px;
  align-items: center;
  gap: 6px;

  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  cursor: pointer;
}

/* Hide the default checkbox */
.toggle input {
  display: none;
}

/* Style the toggle switch */
.toggle {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 12px;
}

.toggle_container:hover {
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4); /* 토글 배경색 */
  transition: 0.4s;
  border-radius: 12px;
}

.toggle .slider:before {
  position: absolute;
  content: "";
  height: 8px; /* 높이에 맞게 변경 */
  width: 8px; /* 높이에 맞게 변경 */
  left: 2px;
  bottom: 2px;
  background-color: #7c7d7d; /*이 색이 토글 내부 동그라미 색*/
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: rgba(0, 0, 0, 0.4); /* 선택 됐을 때 배경색 */
}

input:checked + .slider:before {
  transform: translateX(8px);
  background-color: #fff; /* 선택됐을 때 동그라미 색 변경 */
}

/* Style the label */
.toggle_label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--g100, #d9d9d9);
  text-overflow: ellipsis;

  /* body-s */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.12px;
}

.datepicker-wrap {
  width: 120px;
  position: absolute;
  top: 35px;
  z-index: 99999;
  animation: dropFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

.datepickerTimeWrap {
  position: absolute;
  top: 35px;
  left: 0px;
  z-index: 99999;
  animation: dropFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes dropFadeIn {
  from {
    opacity: 0;
    /* transform: translateY(-14px); */
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.date-dropdown-time {
  display: flex;
  width: 79px;
  height: 26px;
  padding: 0px 10px;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;

  height: 30px;
  border: 1px solid rgba(84, 89, 89, 1);
  color: white;
  border-radius: 6px;
  text-align: center;
  line-height: 30px;
  position: relative;
}

.selectedGuestRowDelete {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  transition: all 200ms cubic-bezier(0, 1, 0, 1);

  box-sizing: border-box;

  cursor: pointer;
  position: relative;
}

.selectedGuestRowDelete:hover {
  position: relative;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.selectedGuestRowDelete:hover .selectedGuestRowDeleteIcon {
  fill: #fff;
}

.selectedGuestRowDeleteIcon {
  /* transform: rotate(45deg); */
  margin-left: 1px;
  margin-bottom: 1px;
  fill: var(--w-24, rgba(255, 255, 255, 0.24));
}

.repeat_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  position: relative;
  border-radius: 5px;
}

.repeat_btn:hover {
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  fill: rgba(217, 217, 217, 1);
}

.repeat_btn:hover .repeatIcon {
  fill: #fff;
}

.repeat_btn--active {
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  fill: rgba(217, 217, 217, 1);
}

.repeat_btn--active .repeatIcon {
  fill: #fff;
}

.repeat_btn--setted {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  position: relative;
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-16, rgba(255, 255, 255, 0.16));
}

.repeat_btn--setted .repeatIcon {
  fill: #fff;
}

.repeat_btn--setted:hover {
  position: relative;
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-24, rgba(255, 255, 255, 0.24));
  fill: #fff;
}
.repeatIcon {
  fill: #abadad;
}

.iconDescription {
  width: max-content;
  position: absolute;
  top: -23px;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s,
    opacity 0.3s linear;
  padding: 2px 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background: var(--line-btn, #545959);
  overflow: hidden;
  color: var(--g100, #d9d9d9);
  text-overflow: ellipsis;

  /* tooltip */
  font-family: "Pretendard Variable";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.11px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
}

.repeat_btn:hover .iconDescription,
.repeat_btn--setted:hover .iconDescription,
.selectedGuestRowDelete:hover .iconDescription {
  position: absolute;
  visibility: visible;
  opacity: 1;
  transition:
    visibility 0s,
    opacity 0.3s linear;
}
