.selectedGuestRowDelete {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  transition: all 200ms cubic-bezier(0, 1, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;

  position: absolute;
  right: 3px;
  border-radius: 5px;
  background: var(--dropdown_dark, rgba(36, 38, 38, 0.8));
  backdrop-filter: blur(2px);
}

.selectedGuestRowDelete:hover {
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  backdrop-filter: blur(2px);
  background: var(--dropdown_dark, rgba(36, 38, 38, 0.8));
}

.selectedGuestRowDelete:hover .selectedGuestRowDeleteIcon {
  fill: #fff;
}

.selectedGuestRowDeleteIcon {
  margin-left: 1px;
  margin-bottom: 1px;
  fill: var(--w-24, rgba(255, 255, 255, 0.24));
}
