.project__create__area {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background: #1a1c1c;
  overflow: hidden;
}

.header {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: start;
  height: 52px;
  padding-top: 28px;
  padding-left: 30px;
  margin-bottom: 28px;
}

.header__button {
  display: flex;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.project__body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 80px);
}

.project__info {
  display: flex;
  flex-grow: 1;
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 80px);
}

.project__info::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 4px;
}

.project__info::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(255, 255, 255, 0);
}

.project__info:hover::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.2);
}

.project__info::-webkit-scrollbar-track {
  background: #1a1c1c;
}

.project__timeline {
  display: flex;
  height: calc(100vh - 80px);
  min-width: 500px;
  max-width: 500px;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.project__timeline::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 4px;
}

.project__timeline::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(255, 255, 255, 0);
}

.project__timeline:hover::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.2);
}

.project__timeline::-webkit-scrollbar-track {
  background: #1a1c1c;
}

.timeline__vertical {
  margin-left: 68px;
  width: 1px;
  background: #363a3a;
  height: 100%;
}

.projectUntitle {
  width: 100%;
  height: 34px;
  color: #fff;
  text-overflow: ellipsis;
  font-size: 28px;
  font-weight: 500;
  caret-color: #6bdcff;
  background: #1a1c1c;
  outline: none;
  border: none;
}

.project__untitle::placeholder {
  color: #7c7d7d;
}

.project__description {
  caret-color: #6bdcff;
  background: #1a1c1c;
  outline: none;
  color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  min-height: 126px;
  overflow-y: hidden;
  resize: none;
}

.project__description::placeholder {
  font-family: "Pretendard Variable";
  color: #7c7d7d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.project__line {
  width: 100%;
  height: 1px;
  background: #363a3a;
}

.bookmark__default {
  width: 24px;
  height: 24px;
  user-select: none;
  pointer-events: none;
}

.bookmark__title {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.setting-property-resource {
  margin: 20px 0px;
  gap: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
}

.resource-item {
  width: 88px;
  height: 88px;
  border-radius: 10px;
  border: 1px solid rgba(84, 89, 89, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  position: relative;
}

.resource-item-delete {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  right: 5px;
  cursor: pointer;
  background: url(../../assets/TaskDetail/resource-delete.svg);
}

.resource-item:has(.resource-item-favicon) {
  background: rgba(54, 58, 58, 1);
}

.resource-item:has(.resource-item-text):hover,
.resource-item:has(.resource-item-text):hover .resource-item-text {
  color: white;
  border-color: white;
}

.resource-item-favicon {
  width: 30px;
  height: 30px;
  border: none;
  line-height: 22px;
  background-size: contain;
}

.timeBlockListArea {
  display: flex;
  flex-direction: column;
  width: 500px;
  box-sizing: border-box;
  position: absolute;
}

.monthArea {
  display: flex;
  margin-left: 80px;
  width: 100%;
}

.timeBlockArea {
  display: flex;
  position: relative;
  margin-top: 15px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
